const dict = {
  chooseLang: "Choisir la langue",

  // Licence
  instructions: "Instructions",
  step1: "Étape 1 : Scanner le code QR",
  locateqr: "Trouvez le code QR sur votre droite.",
  useSmartphone:
    "Utilisez l’appareil photo de votre smartphone ou une application de scan de QR Code pour le scanner. Cela vous mènera à l’étape suivante.",
  step2: "Étape 2 : Entrer les informations de la playlist",
  afterScan: "Après avoir scanné le code QR, vous serez invité à entrer les informations de votre playlist.",
  makeSure: "Assurez-vous de remplir tous les champs requis.",
  step3: "Étape 3 : Retourner à l'application",
  onceYou:
    "Une fois que vous avez entré les détails de votre playlist, vous pouvez retourner à l'application et poursuivre votre expérience.",
  refresh: "ACTUALISER",
  playlist: "Playlist",
  bigPlaylist: "PLAYLISTS",
  noLicence: "Vous n'avez pas de licence.",
  scanLicence: "Scannez pour acheter une licence.",
  noPlaylist: "Vous n'avez aucune playlist",
  privacyBig: "MODE PRIVÉ",
  scanToAddBig: "SCANNER POUR AJOUTER UNE PLAYLIST",
  scanToBuyBig: "SCANNER POUR ACHETER UNE LICENCE",
  orVisit: "ou visitez",
  andenterdeviceID: "et entrez cet identifiant d'appareil :",
  removePlaylist: "Supprimer la playlist",
  wantToRemove: "Voulez-vous la supprimer ?",
  cancelBig: "ANNULER",

  // Accueil
  bigLiveTv: "TV EN DIRECT",
  bigMovies: "FILMS",
  bigSeries: "SÉRIES",

  // Menu des chaînes
  noChannels: "Cette playlist ne contient aucune donnée de chaîne",
  searchChannels: "Rechercher une chaîne",
  tapToPlay: "Touchez pour lire le flux vidéo.",
  categoryEmpty: "Cette catégorie est vide",

  // Films
  noMovies: "Cette playlist ne contient aucune donnée de film",
  searchMovies: "Rechercher des films",

  // Séries
  noSeries: "Cette playlist ne contient aucune donnée de série",
  searchSeries: "Rechercher des séries",

  // Entité
  play: "LECTURE",
  recommendations: "Recommandations",

  // Informations utilisateur
  userInfo: "Informations utilisateur",
  aboutApp: "À propos de l'application",

  // Compte utilisateur
  account: "Compte",
  subscriptions: "Abonnements",
  privacyPilicy: "Confidentialité & Politique",
  languge: "Langue",
  english: "Anglais",
  italian: "Italien",

  // Derniers détails
  playInfo: "Informations de la playlist",
  playName: "Nom de la playlist :",
  host: "Hôte :",
  username: "Nom d'utilisateur :",
  password: "Mot de passe :",
  packName: "Nom du forfait :",
  expireDate: "Date d'expiration :",

  // Politique de confidentialité
  one: "1. Avertissement sur le contenu : OrbixPlay est une plateforme conçue pour améliorer votre expérience numérique. Cependant, il est important de noter qu’OrbixPlay n'héberge, ne fournit ni ne distribue de contenu vidéo. Notre service est conçu pour accompagner votre parcours numérique, mais nous ne sommes pas responsables de la disponibilité, de la qualité ou du contenu des vidéos, car cela dépasse le cadre de nos services.",
  two: "2. Aucune responsabilité pour le contenu tiers : OrbixPlay LLC n'est pas responsable du contenu tiers auquel vous accédez via notre application. Votre interaction avec tout contenu externe, y compris sa légalité, sa fiabilité ou sa pertinence, se fait entièrement à vos risques et périls.",
  three:
    "3. Juridiction et conformité : OrbixPlay LLC opère sous la juridiction des États-Unis. Il est de la responsabilité des utilisateurs de s’assurer que l’utilisation de l’application est conforme aux lois locales, nationales et fédérales.",
  four: "4. Politique de prix : Les prix des services OrbixPlay peuvent être modifiés à tout moment sans préavis. En acceptant cet accord, vous reconnaissez et acceptez que les prix puissent varier et que ces modifications n'affecteront pas votre service actuel jusqu'au renouvellement de votre abonnement.",
  five: `5. Absence de garantie : L'application OrbixPlay et ses services sont fournis "tels quels" sans aucune garantie expresse ou implicite, y compris, mais sans s'y limiter, les garanties implicites de qualité marchande, d'adéquation à un usage particulier et de non-contrefaçon.`,
  six: "6. Acceptation des conditions : En cliquant sur Accepter, vous acceptez d’être lié par les termes de cet avertissement et reconnaissez que toute violation peut entraîner la résiliation de votre accès aux services OrbixPlay.",
  orbixUserAgreement: "CONTRAT D’UTILISATEUR ORBIXPLAY ET AVERTISSEMENT",
  beforeyouProcc: "Avant de continuer, veuillez lire et accepter les conditions suivantes :",

  //additional
  exitApp: "Quitter OrbixPlay",
  wantToExit: "Êtes-vous sûr de vouloir quitter OrbixPlay ?",
};

export default dict;
