import "globalthis/polyfill";
import { createRenderer, Config, loadFonts } from "@lightningtv/solid";
import { WebGlCoreRenderer, SdfTextRenderer } from "@lightningjs/renderer/webgl";
import { Inspector } from "@lightningjs/renderer/inspector";
import { HashRouter, Route } from "@solidjs/router";
import { onMount } from "solid-js";
import * as Sentry from "@sentry/solid";
import { solidRouterBrowserTracingIntegration } from "@sentry/solid/solidrouter";
import App from "./pages/App";
// import TextPage from "./pages/Text";
import NotFound from "./pages/NotFound";
import fonts from "./fonts";
import LicenceScreen from "./pages/Licence";
import HomePage from "./pages/Home";
import ChannelMenu from "./pages/livetv/ChannelMenu";
import Movies from "./pages/movies/Movies";
import Series from "./pages/series/Series";
import Entity from "./pages/Entity";
import LiveTvPage from "./pages/livetv/LIVETV";
import SearchPage from "./pages/search/search";
import SearchSeriesPage from "./pages/search/searchSeries";
import SearchLive from "./pages/search/searchLive";
import Player from "./pages/movies/playerMovie";
import SerieDetails from "./pages/SerieDetails";
import SettingsPage from "./pages/settings/Settings";
import AboutApp from "./pages/settings/AboutApp";
import UserInfo from "./pages/settings/UserInfo";

onMount(() => {
  // Clear localStorage
  localStorage.clear();

  // Clear browser cache
  if ("caches" in window) {
    caches.keys().then(keys => {
      keys.forEach(key => {
        caches.delete(key);
      });
    });
  }
});

Config.debug = false;
Config.fontSettings.fontFamily = "Roboto";
Config.focusStateKey = "focus";
Config.fontSettings.color = 0xffffffff;
Config.rendererOptions = {
  numImageWorkers: 12,
  fontEngines: [SdfTextRenderer],
  renderEngine: WebGlCoreRenderer,
  inspector: Inspector,
  // Set the resolution based on window height
  // 720p = 0.666667, 1080p = 1, 1440p = 1.5, 2160p = 2
  deviceLogicalPixelRatio: window.innerHeight / 1080,
  devicePhysicalPixelRatio: 1,
  // Increase to preload images coming from offscreen
  boundsMargin: 20,
};

// Sentry.init({
//   dsn: `https://a97d740627243abd81c5d0356e28a3a4@o4506824143929344.ingest.us.sentry.io/4508330065854464`,
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for tracing.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
//   tracePropagationTargets: ["localhost"],

//   // Capture Replay for 10% of all sessions,
//   // plus 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });
//@ts-ignore
if (import.meta.env.MODE === "production") {
  console.warn = () => {}; // Disable all warnings in production
}

const root = document.getElementById("app");
//@ts-ignore
if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error(
    "Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?",
  );
}

const { render } = createRenderer();
loadFonts(fonts);
render(
  () => (
    <HashRouter root={App}>
      <Route path="/" component={LicenceScreen} />
      <Route path="/home" component={HomePage} />
      <Route path="/channel-menu" component={ChannelMenu} />
      <Route path="/movies" component={Movies} />
      <Route path="/series" component={Series} />
      <Route path="serieDetails/:type/:id/:category_id" component={SerieDetails} />
      {/* <Route path="/text" component={TextPage} /> */}
      <Route path="/livetv" component={LiveTvPage} />
      <Route path="/search" component={SearchPage} />
      <Route path="/searchseries" component={SearchSeriesPage} />
      <Route path="/searchlive" component={SearchLive} />
      <Route path="entity/:type/:id/:category_id" component={Entity} />
      <Route path="/player" component={Player} />

      <Route path="/settings" component={SettingsPage} />
      <Route path="/aboutApp" component={AboutApp} />
      <Route path="/userInfo" component={UserInfo} />

      <Route path="/*all" component={NotFound} />
    </HashRouter>
  ),
  //@ts-ignore
  root,
);
