import { spawn, Thread } from "threads";
import { deviceYear } from "../../../state";

let worker: any; // Keep worker instance alive
let cache = new Map<string, any>(); // Cache to persist data across screens

const year = deviceYear();

const getWorker = async () => {
  if (!worker) {
    //@ts-ignore
    worker = await spawn(new Worker(new URL("./fetchWorker.ts", import.meta.url)));
  }
  return worker;
};

// Function to fetch data with caching
const fetchData = async (url: string) => {
  if (cache.has(url)) {
    console.log("Returning cached data from main thread");
    return cache.get(url);
  }

  const workerInstance = await getWorker();
  const result = await workerInstance.fetchData(url);

  cache.set(url, result); // Store in cache

  return result;
};

// Cleanup worker when app is closed
window.addEventListener("beforeunload", () => {
  if (worker) {
    Thread.terminate(worker);
  }
});

export { fetchData };

// import { spawn, Thread } from "threads";
// import { deviceYear } from "../../../state";

// let worker: any; // Keep worker instance alive
// let cache = new Map<string, any>(); // Cache to persist data across screens

// const year = deviceYear();

// const getWorker = async () => {
//   if (!worker) {
//     //@ts-ignore
//     worker = await spawn(new Worker(new URL("./fetchWorker.ts", import.meta.url)));
//     // worker = await spawn(new Worker(new URL("./fetchWorker.ts", import.meta.url, { type: "module" })));

//   }
//   return worker;
// };

// // ✅ Fetch in MAIN thread, then process in worker
// const fetchData = async (url: string) => {
//   if (cache.has(url)) {
//     console.log("Returning cached data from main thread");
//     return cache.get(url);
//   }

//   console.log("Fetching in main thread:", url);

//   try {
//     const response = await fetch(url);
//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }
//     const data = await response.json();

//     // Send data to worker for processing
//     const workerInstance = await getWorker();
//     const processedData = await workerInstance.processData(data);

//     cache.set(url, processedData); // Store in cache
//     return processedData;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     throw error;
//   }
// };

// // Cleanup worker when app is closed
// window.addEventListener("beforeunload", () => {
//   if (worker) {
//     Thread.terminate(worker);
//   }
// });

// export { fetchData };
