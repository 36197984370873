import { setIsBuffering } from "../state";

let videoElement;

export const state = {
  playingState: false,
};

const init = (element, zIndex = -1, width = "100vw", height = "100vh", context) => {
  if (!videoElement) {
    videoElement = element || document.createElement("video");
    videoElement.preload = "auto"; // Hint to browser to preload video data

    videoElement.style.cssText = `
        position: absolute;
        top: ${context === "preview" ? "50%" : "0"};
        left: ${context === "preview" ? "50%" : "0"};
        z-index: ${zIndex};
        width: ${width};
        height: ${height};
        background-color: black;
        transform: ${context === "preview" ? "translate(-51.5%, -148%)" : "none"};
      `;

    videoElement.muted = context === "preview";

    videoElement.addEventListener("waiting", () => {
      setIsBuffering(true); // Show buffering indicator
      console.log("Buffering: Loading...");
    });

    videoElement.addEventListener("playing", () => {
      setIsBuffering(false); // Stop buffering indicator
      console.log("Buffering: Playing");
    });

    videoElement.addEventListener("error", () => {
      console.error("Video error occurred.");
      setIsBuffering(true); // Handle errors by setting buffering state
    });

    document.body.insertBefore(videoElement, document.body.firstChild);
  }

  videoElement.autoplay = false;
};

/**
 * Loads a video URL into the default player.
 * @param {Object} config - The player configuration.
 */
const load = config => {
  if (!videoElement) {
    throw "Video element not initialized yet";
  }

  setIsBuffering(true); // Start loading indicator
  try {
    videoElement.src = config.streamUrl; // Set video source URL
    videoElement.load(); // Load the video
  } catch (error) {
    console.error("Error loading stream:", error);
    setIsBuffering(true); // Handle load failure
  }
};

const play = () => {
  videoElement
    .play()
    .then(() => {
      state.playingState = true;
      setIsBuffering(false); // Stop buffering indicator
    })
    .catch(error => {
      console.error("Error starting playback:", error);
      setIsBuffering(true); // Handle playback failure
    });
};

const pause = () => {
  videoElement.pause();
  state.playingState = false;
};

const destroy = () => {
  if (videoElement) {
    videoElement.remove();
    videoElement = null;
  }
};

const getDuration = () => {
  if (!videoElement) {
    console.error("Video element is not initialized");
    throw new Error("Video element is not initialized");
  }

  return new Promise((resolve, reject) => {
    // Check if the duration is already available
    if (!isNaN(videoElement.duration)) {
      console.log("Video element duration1:", videoElement.duration);
      resolve(videoElement.duration);
    } else {
      // Wait for the metadata to load
      videoElement.addEventListener("loadedmetadata", () => {
        console.log("Video element duration2:", videoElement.duration);
        resolve(videoElement.duration);
      });

      // Handle errors if metadata fails to load
      videoElement.addEventListener("error", e => {
        console.error("Error retrieving video duration:", e);
        reject(new Error("Failed to load video metadata"));
      });
    }
  });
};

const getCurrentTime = () => {
  if (!videoElement) {
    throw new Error("Video element is not initialized");
  }
  return videoElement.currentTime;
};

const onTimeUpdate = callback => {
  if (!videoElement) {
    throw new Error("Video element is not initialized");
  }
  videoElement.addEventListener("timeupdate", callback);
};

export const playVideo = async (streamUrl, context) => {
  let zIndex = -1;
  let width = "100vw";
  let height = "100vh";

  if (context === "preview") {
    zIndex = 1;
    width = "30vw";
    height = "30vh";
  } else if (context === "splash") {
    zIndex = 1;
    width = "100vw";
    height = "100vh";
  }

  init(null, zIndex, width, height, context);

  // Load the video URL into the player
  load({ streamUrl });

  // Start playback
  play();
};

const seek = time => {
  if (!videoElement) {
    throw new Error("Video element is not initialized");
  }
  videoElement.currentTime = time; // Set the current time

  // Autoplay the video after seeking
  videoElement.play().catch(error => {
    console.error("Error trying to play the video:", error);
  });
};

export default {
  init,
  load,
  play,
  pause,
  getCurrentTime,
  getDuration,
  onTimeUpdate,
  seek,
  state,
  destroy,
  playVideo,
};

// import { setIsBuffering, setLastStream } from "../state";

// let playerInstance = null;
// let reconnect_count = 0;
// let reconnect_timer = null;
// const MAX_RETRIES = 5;
// let currentUrl = '';
// let context = '';

// // Make reconnection info available globally for UI components
// window.reconnectInfo = {
//   count: reconnect_count,
//   max: MAX_RETRIES,
// };

// const videoPlayerPreview = {
//   destroy() {
//     try {
//       if (webapis && webapis.avplay) {
//         webapis.avplay.stop();
//         webapis.avplay.close();
//       }
//       reconnect_count = 0;
//       window.reconnectInfo.count = 0;
//       clearTimeout(reconnect_timer);
//       setIsBuffering(false);
//     } catch (e) {
//       console.error("Error destroying player:", e);
//     }
//   },

//   playVideo(streamUrl, playerContext = 'preview') {
//     if (currentUrl === streamUrl) {
//       return Promise.resolve();
//     }
//     currentUrl = streamUrl;
//     context = playerContext;
//     reconnect_count = 0;
//     window.reconnectInfo.count = 0;
//     clearTimeout(reconnect_timer);

//     return new Promise((resolve, reject) => {
//       try {
//         this.destroy();

//         const objElem = document.createElement("object");
//         objElem.type = "application/avplayer";

//         if (context === 'preview') {
//           Object.assign(objElem.style, {
//             position: "absolute",
//             left: "49%",
//             top: "72px",
//             transform: "translateX(-50%)",
//             width: "665px",
//             height: "380px",
//             zIndex: "999",
//           });
//         } else {
//           Object.assign(objElem.style, {
//             position: "absolute",
//             left: "0",
//             top: "0",
//             width: "100%",
//             height: "100%",
//             zIndex: "999",
//           });
//         }

//         document.body.appendChild(objElem);
//         playerInstance = objElem;

//         setIsBuffering(true);
//         webapis.avplay.open(streamUrl);
//         webapis.avplay.setBufferingParam("PLAYER_BUFFER_FOR_PLAY", "PLAYER_BUFFER_SIZE_IN_SECOND", 4);
//         webapis.avplay.setBufferingParam("PLAYER_BUFFER_FOR_RESUME", "PLAYER_BUFFER_SIZE_IN_SECOND", 2);

//         const listener = {
//           onbufferingstart: () => {
//             console.log("Buffering started");
//             setIsBuffering(true);
//           },
//           onbufferingprogress: (percent) => {
//             console.log("Buffering progress: " + percent);
//           },
//           onbufferingcomplete: () => {
//             console.log("Buffering completed");
//             setIsBuffering(false);
//             reconnect_count = 0;
//             window.reconnectInfo.count = 0;
//           },
//           onstreamcompleted: () => {
//             console.log("Stream completed");
//           },
//           oncurrentplaytime: () => {},
//           onerror: (eventType) => {
//             console.error("Player error:", eventType);
//             tryReconnect();
//             reject(`Player error: ${eventType}`);
//           },
//           onevent: (eventType, eventData) => {
//             console.log("Event: " + eventType + ", Data: " + eventData);
//           },
//         };

//         webapis.avplay.setListener(listener);

//         webapis.avplay.prepareAsync(() => {
//           if (context === 'preview') {
//             this.setPreviewScreen();
//           } else {
//             this.setFullScreen();
//           }
//           webapis.avplay.play();
//           setIsBuffering(false);
//           resolve();
//         }, (error) => {
//           console.error("Preparation error:", error);
//           tryReconnect();
//           reject(error);
//         });
//       } catch (e) {
//         console.error("Player initialization error:", e);
//         tryReconnect();
//         reject(e);
//       }
//     });
//   },

//   setPreviewScreen() {
//     try {
//       const rect = [660, 60, 580, 320];
//       webapis.avplay.setDisplayRect(rect[0], rect[1], rect[2], rect[3]);
//     } catch (e) {
//       console.error("Error setting preview screen:", e);
//     }
//   },

//   setFullScreen() {
//     try {
//       const rect = [0, 0, window.innerWidth, window.innerHeight];
//       webapis.avplay.setDisplayRect(rect[0], rect[1], rect[2], rect[3]);
//     } catch (e) {
//       console.error("Error setting fullscreen:", e);
//     }
//   }
// };

// function tryReconnect() {
//   clearTimeout(reconnect_timer);
//   reconnect_count++;
//   window.reconnectInfo.count = reconnect_count;

//   if (reconnect_count > MAX_RETRIES) {
//     console.log("Maximum retry attempts reached");
//     setIsBuffering(false);
//     return;
//   }

//   console.log(`Reconnection attempt ${reconnect_count}/${MAX_RETRIES}`);
//   setIsBuffering(true);

//   const delay = Math.min(1000 * Math.pow(2, reconnect_count - 1), 10000);
//   reconnect_timer = setTimeout(() => {
//     try {
//       webapis.avplay.stop();
//       webapis.avplay.close();
//       webapis.avplay.open(currentUrl);
//       webapis.avplay.setBufferingParam("PLAYER_BUFFER_FOR_PLAY", "PLAYER_BUFFER_SIZE_IN_SECOND", 4);
//       webapis.avplay.setBufferingParam("PLAYER_BUFFER_FOR_RESUME", "PLAYER_BUFFER_SIZE_IN_SECOND", 2);
//       webapis.avplay.prepareAsync(() => {
//         webapis.avplay.play();
//         if (context === 'preview') {
//           videoPlayerPreview.setPreviewScreen();
//         } else {
//           videoPlayerPreview.setFullScreen();
//         }
//         setIsBuffering(false);
//       }, () => {
//         tryReconnect();
//       });
//     } catch (e) {
//       console.error("Error during reconnection:", e);
//       tryReconnect();
//     }
//   }, delay);
// }

// export default videoPlayerPreview;
