import { onCleanup, onMount } from "solid-js";
import { gsap } from "gsap";

const LoadingIndicator = props => {
  let gifElement;

  console.log("props", props.isCategoriesIndicator);

  onMount(() => {
    // Set the rotation to a complete turn (360 degrees) without acceleration
    const animation = gsap.to(gifElement, {
      duration: 30, // Duration for a full rotation
      rotation: 360, // Complete rotation
      repeat: -1, // Repeat indefinitely
      ease: "linear", // Use linear easing for constant speed
    });

    onCleanup(() => {
      animation.kill();
    });
  });

  return (
    <div style={{ position: "absolute", "z-index": 9999 }}>
      <img
        ref={gifElement}
        src="https://i.ibb.co/3pcbRDW/loading-1.png"
        alt="Animated GIF"
        width={props.isCategoriesIndicator ? " 150" : "250"}
        height={props.isCategoriesIndicator ? " 150" : "250"}
      />
    </div>
  );
};

export default LoadingIndicator;

// import { createSignal, Show } from "solid-js";
// import { View, Text, hexColor } from "@lightningtv/solid";
// import { isBuffering } from "../../../state";
// const LoadingIndicator = (props) => {
//   // Access retry count from globally exposed variables
//   const getReconnectInfo = () => {
//     if (typeof window.reconnectInfo !== 'undefined') {
//       return window.reconnectInfo;
//     }
//     return { count: 0, max: 5 };
//   };

//   return (
//     <View>
//       <div class="loadingio-spinner-dual-ring-cj4f5bmlqvq">
//         <div class="ldio-3hpkstg4hue">
//           <div></div>
//           <div>
//             <div></div>
//           </div>
//         </div>
//       </div>
//       <Show when={isBuffering() && getReconnectInfo().count > 0}>
//         <Text
//           style={{
//             color: hexColor("#ffffff"),
//             fontSize: 16,
//             marginTop: 10,
//             textAlign: "center"
//           }}
//         >
//           Reconnecting: attempt {getReconnectInfo().count}/{getReconnectInfo().max}
//         </Text>
//       </Show>
//     </View>
//   );
// };
// export default LoadingIndicator;
