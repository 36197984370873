import { createSignal, onCleanup } from "solid-js";

export function useDebounce(fn: any, delay: any) {
  let timeout: any;
  const debounced = (...args: any) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
  onCleanup(() => clearTimeout(timeout));
  return debounced;
}
