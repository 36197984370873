const dict = {
  chooseLang: "Sprache auswählen",

  // Lizenz
  instructions: "Anweisungen",
  step1: "Schritt 1: QR-Code scannen",
  locateqr: "Finde den QR-Code auf der rechten Seite.",
  useSmartphone:
    "Verwende die Kamera deines Smartphones oder eine QR-Code-Scanner-App, um ihn zu scannen. Dies führt dich zum nächsten Schritt.",
  step2: "Schritt 2: Playlist-Informationen eingeben",
  afterScan: "Nach dem Scannen des QR-Codes wirst du aufgefordert, deine Playlist-Informationen einzugeben.",
  makeSure: "Stelle sicher, dass alle erforderlichen Felder ausgefüllt sind.",
  step3: "Schritt 3: Zurück zur App",
  onceYou:
    "Sobald du deine Playlist-Details eingegeben hast, kannst du zur App zurückkehren und deine Erfahrung fortsetzen.",
  refresh: "AKTUALISIEREN",
  playlist: "Playlist",
  bigPlaylist: "PLAYLISTS",
  noLicence: "Du hast keine Lizenz.",
  scanLicence: "Scanne, um eine Lizenz zu kaufen.",
  noPlaylist: "Du hast keine Playlist",
  privacyBig: "PRIVATSPHÄRE-MODUS",
  scanToAddBig: "SCANNEN, UM PLAYLIST HINZUZUFÜGEN",
  scanToBuyBig: "SCANNEN, UM LIZENZ ZU KAUFEN",
  orVisit: "oder besuche",
  andenterdeviceID: "und gib diese Geräte-ID ein:",
  removePlaylist: "Playlist entfernen",
  wantToRemove: "Möchtest du sie entfernen?",
  cancelBig: "ABBRECHEN",

  // Startseite
  bigLiveTv: "LIVE-TV",
  bigMovies: "FILME",
  bigSeries: "SERIEN",

  // Kanalmenü
  noChannels: "Diese Playlist enthält keine Kanaldaten",
  searchChannels: "Kanal suchen",
  tapToPlay: "Tippe, um den Videostream abzuspielen.",
  categoryEmpty: "Diese Kategorie ist leer",

  // Filme
  noMovies: "Diese Playlist enthält keine Filmdaten",
  searchMovies: "Filme suchen",

  // Serien
  noSeries: "Diese Playlist enthält keine Serien-Daten",
  searchSeries: "Serien suchen",

  // Entität
  play: "ABSPIELEN",
  recommendations: "Empfehlungen",

  // Benutzerinformationen
  userInfo: "Benutzerinformationen",
  aboutApp: "Über die App",

  // Benutzerkonto
  account: "Konto",
  subscriptions: "Abonnements",
  privacyPilicy: "Datenschutz & Richtlinien",
  languge: "Sprache",
  english: "Englisch",
  italian: "Italienisch",

  // Letzte Details
  playInfo: "Playlist-Informationen",
  playName: "Playlist-Name:",
  host: "Host:",
  username: "Benutzername:",
  password: "Passwort:",
  packName: "Paketname:",
  expireDate: "Ablaufdatum:",

  // Datenschutzrichtlinie
  one: "1. Haftungsausschluss für Inhalte: OrbixPlay ist eine Plattform, die dein digitales Erlebnis verbessern soll. Es ist jedoch wichtig zu beachten, dass OrbixPlay keine Video-Inhalte hostet, bereitstellt oder vertreibt. Unser Dienst ist darauf ausgelegt, deine digitale Reise zu unterstützen, aber wir sind nicht verantwortlich für die Verfügbarkeit, Qualität oder den Inhalt von Videomaterial, da dies außerhalb unseres Dienstes liegt.",
  two: "2. Keine Haftung für Inhalte Dritter: OrbixPlay LLC haftet nicht für Inhalte Dritter, auf die du über unsere Anwendung zugreifst. Deine Interaktion mit externen Inhalten, einschließlich deren Rechtmäßigkeit, Zuverlässigkeit oder Angemessenheit, erfolgt ausschließlich auf eigenes Risiko.",
  three:
    "3. Zuständigkeit und Einhaltung: OrbixPlay LLC unterliegt der Gerichtsbarkeit der Vereinigten Staaten. Benutzer sind dafür verantwortlich, sicherzustellen, dass die Nutzung der Anwendung mit allen lokalen, staatlichen und bundesstaatlichen Gesetzen übereinstimmt.",
  four: "4. Preispolitik: Die Preise für die Dienste von OrbixPlay können jederzeit ohne vorherige Ankündigung geändert werden. Durch die Annahme dieser Vereinbarung erkennst du an und stimmst zu, dass sich die Preise ändern können und dass solche Änderungen deinen aktuellen Dienst nicht betreffen, bis dein Abonnement erneuert wird.",
  five: `5. Keine Garantie: Die OrbixPlay-Anwendung und ihre Dienste werden "wie besehen" bereitgestellt, ohne ausdrückliche oder stillschweigende Garantien, einschließlich, aber nicht beschränkt auf stillschweigende Garantien der Marktgängigkeit, Eignung für einen bestimmten Zweck und Nichtverletzung.`,
  six: "6. Akzeptanz der Bedingungen: Durch Klicken auf Zustimmen erklärst du dich mit den Bedingungen dieses Haftungsausschlusses einverstanden und erkennst an, dass Verstöße zur Beendigung deines Zugriffs auf die OrbixPlay-Dienste führen können.",
  orbixUserAgreement: "ORBIXPLAY BENUTZERVEREINBARUNG UND HAFTUNGSAUSSCHLUSS",
  beforeyouProcc: "Bevor du fortfährst, lies bitte die folgenden Bedingungen und akzeptiere sie:",

  //additional
  exitApp: "OrbixPlay beenden",
  wantToExit: "Möchten Sie OrbixPlay wirklich beenden?",
};

export default dict;
