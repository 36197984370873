const dict = {
  chooseLang: `Επιλέξτε γλώσσα`,

  // Άδεια
  instructions: `Οδηγίες`,
  step1: `Βήμα 1: Σαρώστε τον κωδικό QR`,
  locateqr: `Βρείτε τον κωδικό QR στα δεξιά σας.`,
  useSmartphone: `Χρησιμοποιήστε την κάμερα του smartphone σας ή μια εφαρμογή σαρωτή QR για να τον σαρώσετε. Αυτό θα σας μεταφέρει στο επόμενο βήμα.`,
  step2: `Βήμα 2: Εισαγάγετε τις πληροφορίες της λίστας αναπαραγωγής`,
  afterScan: `Αφού σαρώσετε τον κωδικό QR, θα σας ζητηθεί να εισαγάγετε τις πληροφορίες της λίστας αναπαραγωγής σας.`,
  makeSure: `Βεβαιωθείτε ότι έχετε συμπληρώσει όλα τα απαιτούμενα πεδία.`,
  step3: `Βήμα 3: Επιστροφή στην εφαρμογή`,
  onceYou: `Μόλις εισαγάγετε τις λεπτομέρειες της λίστας αναπαραγωγής, μπορείτε να επιστρέψετε στην εφαρμογή και να συνεχίσετε την εμπειρία σας.`,
  refresh: `ΑΝΑΝΕΩΣΗ`,
  playlist: `Λίστα αναπαραγωγής`,
  bigPlaylist: `ΛΙΣΤΕΣ ΑΝΑΠΑΡΑΓΩΓΗΣ`,
  noLicence: `Δεν έχετε άδεια.`,
  scanLicence: `Σαρώστε για να αγοράσετε άδεια.`,
  noPlaylist: `Δεν έχετε καμία λίστα αναπαραγωγής`,
  privacyBig: `ΛΕΙΤΟΥΡΓΙΑ ΙΔΙΩΤΙΚΟΤΗΤΑΣ`,
  scanToAddBig: `ΣΑΡΩΣΤΕ ΓΙΑ ΠΡΟΣΘΗΚΗ ΛΙΣΤΑΣ`,
  scanToBuyBig: `ΣΑΡΩΣΤΕ ΓΙΑ ΑΓΟΡΑ ΑΔΕΙΑΣ`,
  orVisit: `ή επισκεφθείτε`,
  andenterdeviceID: `και εισαγάγετε αυτό το αναγνωριστικό συσκευής:`,
  removePlaylist: `Αφαίρεση λίστας αναπαραγωγής`,
  wantToRemove: `Θέλετε να την αφαιρέσετε;`,
  cancelBig: `ΑΚΥΡΩΣΗ`,

  // Αρχική σελίδα
  bigLiveTv: `ΖΩΝΤΑΝΗ ΤΗΛΕΟΡΑΣΗ`,
  bigMovies: `ΤΑΙΝΙΕΣ`,
  bigSeries: `ΣΕΙΡΕΣ`,

  // Μενού καναλιών
  noChannels: `Αυτή η λίστα αναπαραγωγής δεν περιέχει δεδομένα καναλιών`,
  searchChannels: `Αναζήτηση καναλιού`,
  tapToPlay: `Πατήστε για αναπαραγωγή του βίντεο.`,
  categoryEmpty: `Αυτή η κατηγορία είναι κενή`,

  // Ταινίες
  noMovies: `Αυτή η λίστα αναπαραγωγής δεν περιέχει δεδομένα ταινιών`,
  searchMovies: `Αναζήτηση ταινιών`,

  // Σειρές
  noSeries: `Αυτή η λίστα αναπαραγωγής δεν περιέχει δεδομένα σειρών`,
  searchSeries: `Αναζήτηση σειρών`,

  // Οντότητα
  play: `ΑΝΑΠΑΡΑΓΩΓΗ`,
  recommendations: `Προτάσεις`,

  // Πληροφορίες χρήστη
  userInfo: `Πληροφορίες χρήστη`,
  aboutApp: `Σχετικά με την εφαρμογή`,

  // Πληροφορίες λογαριασμού
  account: `Λογαριασμός`,
  subscriptions: `Συνδρομές`,
  privacyPilicy: `Απόρρητο και Πολιτική`,
  languge: `Γλώσσα`,
  english: `Αγγλικά`,
  italian: `Ιταλικά`,

  // Τελευταίες πληροφορίες
  playInfo: `Πληροφορίες λίστας αναπαραγωγής`,
  playName: `Όνομα λίστας αναπαραγωγής:`,
  host: `Διακομιστής:`,
  username: `Όνομα χρήστη:`,
  password: `Κωδικός πρόσβασης:`,
  packName: `Όνομα πακέτου:`,
  expireDate: `Ημερομηνία λήξης:`,

  // Πολιτική απορρήτου
  one: `1. Αποποίηση ευθυνών περιεχομένου: Το OrbixPlay είναι μια πλατφόρμα που έχει σχεδιαστεί για να βελτιώσει την ψηφιακή σας εμπειρία. Ωστόσο, είναι σημαντικό να σημειωθεί ότι το OrbixPlay δεν φιλοξενεί, παρέχει ή διανέμει περιεχόμενο βίντεο. Η υπηρεσία μας έχει σχεδιαστεί για να υποστηρίζει το ψηφιακό σας ταξίδι, αλλά δεν φέρουμε ευθύνη για τη διαθεσιμότητα, την ποιότητα ή το περιεχόμενο του υλικού βίντεο, καθώς αυτό βρίσκεται εκτός του πεδίου εφαρμογής των υπηρεσιών μας.`,
  two: `2. Καμία ευθύνη για περιεχόμενο τρίτων: Η OrbixPlay LLC δεν φέρει ευθύνη για οποιοδήποτε περιεχόμενο τρίτων που μπορεί να έχετε πρόσβαση μέσω της εφαρμογής μας. Η αλληλεπίδρασή σας με οποιοδήποτε εξωτερικό περιεχόμενο, συμπεριλαμβανομένης της νομιμότητας, της αξιοπιστίας ή της καταλληλότητάς του, γίνεται αποκλειστικά με δική σας ευθύνη.`,
  three: `3. Δικαιοδοσία και συμμόρφωση: Η OrbixPlay LLC λειτουργεί υπό τη δικαιοδοσία των Ηνωμένων Πολιτειών. Οι χρήστες είναι υπεύθυνοι για τη διασφάλιση ότι η χρήση της εφαρμογής συμμορφώνεται με όλους τους τοπικούς, πολιτειακούς και ομοσπονδιακούς νόμους.`,
  four: `4. Πολιτική τιμών: Οι τιμές για τις υπηρεσίες του OrbixPlay υπόκεινται σε αλλαγές ανά πάσα στιγμή χωρίς προηγούμενη ειδοποίηση. Αποδεχόμενοι αυτή τη συμφωνία, αναγνωρίζετε και συμφωνείτε ότι οι τιμές ενδέχεται να διαφέρουν και ότι οποιεσδήποτε αλλαγές δεν θα επηρεάσουν την τρέχουσα υπηρεσία σας μέχρι την ανανέωση της συνδρομής σας.`,
  five: `5. Χωρίς εγγύηση: Η εφαρμογή OrbixPlay και οι υπηρεσίες της παρέχονται "ως έχουν", χωρίς καμία ρητή ή σιωπηρή εγγύηση, συμπεριλαμβανομένων, ενδεικτικά, των σιωπηρών εγγυήσεων εμπορευσιμότητας, καταλληλότητας για συγκεκριμένο σκοπό και μη παραβίασης.`,
  six: `6. Αποδοχή των όρων: Πατώντας Συμφωνώ, συμφωνείτε να δεσμεύεστε από τους όρους αυτής της αποποίησης ευθύνης και αναγνωρίζετε ότι οποιαδήποτε παραβίαση μπορεί να οδηγήσει σε τερματισμό της πρόσβασής σας στις υπηρεσίες OrbixPlay.`,
  orbixUserAgreement: `ΣΥΜΦΩΝΙΑ ΧΡΗΣΤΗ ΚΑΙ ΑΠΟΠΟΙΗΣΗ ΕΥΘΥΝΗΣ ORBIXPLAY`,
  beforeyouProcc: `Πριν συνεχίσετε, διαβάστε και αποδεχτείτε τους ακόλουθους όρους και προϋποθέσεις:`,

  //additional
  exitApp: "Έξοδος από το OrbixPlay",
  wantToExit: "Είστε σίγουροι ότι θέλετε να εξέλθετε από το OrbixPlay;",
};

export default dict;
