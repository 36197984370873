import { Show, Text, View, hexColor } from "@lightningtv/solid";
import { For, createEffect, createSignal } from "solid-js";
import { playListPassword, playListUrl, playListUsername } from "../../../../state";
import axios from "axios";
import { Buffer } from "buffer";

const SelectedChannelEpg = (props: any) => {
  const [epgData, setEpgData] = createSignal<any>(null);
  const [firstProgram, setFirstProgram] = createSignal("");
  const [secondProgram, setSecondProgram] = createSignal("");
  const [firstProgramDescription, setFirstProgramDescription] = createSignal("");
  const [firstProgramStartTime, setFirstProgramStartTime] = createSignal("");
  const [firstProgramEndTime, setFirstProgramEndTime] = createSignal("");

  // Function to fetch the EPG data
  const fetchEpg = () => {
    const username = playListUsername();
    const password = playListPassword();
    const playList = playListUrl();
    const stream_id = props.channel.stream_id;
    const vpnStatus = localStorage.getItem("vpn");
    const proxyUrl = localStorage.getItem("proxy_url");

    if (!stream_id) {
      console.warn("Stream ID is undefined, retrying in 500ms.");
      setTimeout(fetchEpg, 500); // Retry after 500ms
      return;
    }

    let apiUrl = `${playList}/player_api.php?username=${username}&password=${password}&action=get_short_epg&stream_id=${stream_id}`;

    // If VPN is on and proxy_url exists, buffer the API URL and use proxy
    if (vpnStatus === "on" && proxyUrl) {
      const bufferedUrl = Buffer.from(apiUrl, "utf-8").toString("base64");
      apiUrl = `${proxyUrl}${bufferedUrl}`;
    }

    // Fetch the data from the API and set the signal
    axios
      .get(apiUrl)
      .then(response => {
        setEpgData(response.data);

        setFirstProgram(
          response.data.epg_listings[0]
            ? atob(response.data.epg_listings[0]?.title)
            : `Programs of ${props.channel.name}`,
        );
        setSecondProgram(
          response.data.epg_listings[1]
            ? atob(response.data.epg_listings[1]?.title)
            : `Programs of ${props.channel.name}`,
        );
        setFirstProgramDescription(
          response.data.epg_listings[0]
            ? atob(response.data.epg_listings[0]?.description)
            : "No epg description",
        );
        setFirstProgramStartTime(
          response.data.epg_listings[0]?.start_timestamp
            ? convertToTime(response.data.epg_listings[0]?.start_timestamp)
            : " ",
        );
        setFirstProgramEndTime(
          response.data.epg_listings[0]?.stop_timestamp
            ? convertToTime(response.data.epg_listings[0]?.stop_timestamp)
            : " ",
        );
      })
      .catch(error => {
        setEpgData(null); // Handle error by setting null or other fallback value
        console.error("Failed to fetch EPG data", error);
      });
  };

  // Fetch the EPG data when the component mounts
  createEffect(() => {
    fetchEpg();
  }, [props, epgData()]);

  const convertToTime = (timestamp: number) => {
    const date = new Date(timestamp * 1000); // Multiply by 1000 if it's a UNIX timestamp
    const hours = date.getHours().toString().padStart(2, "0"); // Get hours and pad with leading zero
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Get minutes and pad with leading zero
    return `${hours}:${minutes}`;
  };

  function splitTextIntoRows(text: string, maxChars: number) {
    const rows: any = [];
    for (let i = 0; i < text.length; i += maxChars) {
      rows.push(text.slice(i, i + maxChars));
    }

    if (rows.length > 3) {
      rows[2] = rows[2].slice(0, maxChars - 3) + "..."; // Adding ellipsis to the third row
    }

    return rows.slice(0, 3);
  }

  return (
    <View width={900} clipping plinko x={10} {...props}>
      <Show when={firstProgram() && secondProgram()} fallback={<Text y={10} x={20}></Text>}>
        <View x={40} y={20}>
          <Text style={{ color: hexColor("#ffffff"), fontSize: 28 }}>{props.channel.name}</Text>

          <View y={40} width={23} height={23} src={"https://i.ibb.co/tMMZfqz/live.png"} />
          <Text
            x={35}
            y={40}
            style={{ color: hexColor("#ffffff"), fontSize: 23, textTransform: "uppercase" }}
          >
            {firstProgram() || `Programs of ${props.channel.name}`}{" "}
          </Text>

          <Show when={firstProgramStartTime() && firstProgramEndTime()}>
            <Text x={35} y={80} style={{ color: hexColor("#7d7e82"), fontSize: 18 }}>
              {firstProgramStartTime()} - {firstProgramEndTime()}{" "}
            </Text>
          </Show>

          {/* <Text x={30} y={120} style={{ color: hexColor('#ffffff'), fontSize: 16 }}>{firstProgramDescription()}</Text> */}

          <For each={splitTextIntoRows(firstProgramDescription(), 45)}>
            {(row, index) => (
              <Text x={35} y={110 + index() * 30} style={{ color: hexColor("#7d7e82"), fontSize: 20 }}>
                {row}
              </Text>
            )}
          </For>

          <View y={220} width={23} height={23} src={"https://i.ibb.co/FBQk1s5/next.png"} />
          <Text x={35} y={220} style={{ color: hexColor("#ffffff"), fontSize: 21 }}>
            {secondProgram() || `Programs of ${props.channel.name}`}
          </Text>
        </View>
      </Show>

      {/* <View
                x={40}
                y={300}
                width={300}
                height={50}
                color={hexColor('#222222')}
                clipping
                plinko
                onLeft={() => {
                    props.preview.setFocus();
                }}
                onDown={() => {
                    props.viewRef.setFocus();
                }}
                style={{
                    color: hexColor('#222222'),
                    focus: { color: hexColor('#128DD3'), textColor: hexColor('#ffffff') },
                    borderRadius: 10,

                }}

                onFocus={()=>{console.log("focused buttonnnn");
                }}
            >
                <Text x={20} y={14} style={{ color: hexColor('#ffffff'), fontSize: 15 }}>
                    {props.isFavChannel ? 'Remove from Favorites' : 'Add to Favorites'}
                </Text>


                <View
                    x={250}
                    y={13}
                    width={25}
                    height={25}
                    src={props.isFavChannel ? "./assets/favChannel.png" : "./assets/notFavChannel.png"}
                />

            </View> */}
    </View>
  );
};

export default SelectedChannelEpg;
