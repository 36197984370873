import api from "..";
import { playListPassword, playListUsername } from "../../../state";
import { convertItemsToTilesSeries, chunkArraySeries } from "../formatters/ItemFormatter";

const cache = new Map<string, Promise<any>>();
const leftoverTiles = new Map<string, any[]>();

export default function (filter: string, category_id: number) {
  return (pageIndex: number): Promise<any> => {
    const username = playListUsername();
    const password = playListPassword();
    //@ts-ignore
    const apiIdentifier = api.getIdentifier ? api.getIdentifier() : "default"; // Optional unique identifier for API instance

    const cacheKey = `${apiIdentifier}_${username}_series_${category_id}`;
    const tileKey = `${filter}_${apiIdentifier}_${username}_series_${category_id}`;

    const url = `/player_api.php?username=${username}&password=${password}&action=get_series&category_id=${category_id}`;

    if (cache.has(cacheKey)) {
      //@ts-ignore
      return cache.get(cacheKey);
    }

    const result = api._get(url).then(series => {
      const results = series;
      const existingTiles = leftoverTiles.get(tileKey) || [];
      const tiles = existingTiles.concat(convertItemsToTilesSeries(results));

      const chunks = chunkArraySeries(tiles);
      if (chunks[chunks.length - 1]?.length < 7) {
        leftoverTiles.set(tileKey, chunks.pop() || []);
      } else {
        leftoverTiles.delete(tileKey);
      }

      return tiles;
    });

    cache.set(cacheKey, result);
    return result;
  };
}
