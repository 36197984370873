import { Text, View, hexColor } from "@lightningtv/solid";
import { Show, createEffect, createSignal } from "solid-js";
import { t } from "../../../translations/configuration";
const Subscriptions = (props: any) => {
  const [data, setData] = createSignal(null);
  const [packageName, setPackageName] = createSignal(null);
  createEffect(() => {
    const storedData = localStorage.getItem("licenceData");
    if (storedData) {
      try {
        // Parse the JSON string into an object
        const parsedData = JSON.parse(storedData);
        setData(parsedData);
      } catch (error) {
        console.error("Failed to parse storedData:", error);
      }
    }
  });
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      // timeZoneName: 'short'
    });
  }
  return (
    <View ref={props.ref} x={600} y={100} height={400} width={2000} clipping>
      <Text style={{ fontSize: 35, color: hexColor("#ffffff") }}>{t("subscriptions")}</Text>
      <Show when={data() && data().package_name}>
        <View
          y={90}
          style={{
            width: 1200,
            height: 60,
            borderRadius: 10,
            color: hexColor("#222222"),
            transition: {
              scale: { duration: 250, easing: "ease-in-out" },
            },
            focus: { color: hexColor("#128DD3") },
          }}
          clipping
        >
          <Text x={20} y={17} style={{ fontSize: 28, color: hexColor("#ffffff") }}>
            {t("packName")}{" "}
          </Text>
          <Text x={1000} y={17} style={{ fontSize: 28, color: hexColor("#ffffff"), paddingRight: 20 }}>
            {data().package_name}
          </Text>
        </View>
      </Show>
      <Show when={data() && data().license_end_date}>
        <View
          y={170}
          style={{
            width: 1200,
            height: 60,
            borderRadius: 10,
            color: hexColor("#222222"),
            transition: {
              scale: { duration: 250, easing: "ease-in-out" },
            },
            focus: { color: hexColor("#128DD3") },
          }}
          clipping
        >
          <Text x={20} y={17} style={{ fontSize: 28, color: hexColor("#ffffff") }}>
            {t("expireDate")}{" "}
          </Text>
          <Text x={800} y={17} style={{ fontSize: 28, color: hexColor("#ffffff") }}>
            {formatDate(data().license_end_date)}
          </Text>
        </View>
      </Show>
    </View>
  );
};
export default Subscriptions;
