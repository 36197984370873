import api from "..";
import { playListPassword, playListUsername } from "../../../state";
import {
  convertItemsToTiles,
  chunkArray,
  convertItemsToTilesChannel,
  convertItemsToTilesSeries,
  convertItemsToTilesMovies,
  convertItemsToTilesSeriesEpisodes,
} from "../formatters/ItemFormatter";

let cache = new Map();
const leftoverTiles = new Map();
export default function (filter, category_id, seasonNumber) {
  return async function (pageIndex) {
    const username = playListUsername();
    const password = playListPassword();

    const url = `/player_api.php?username=${username}&password=${password}&action=get_series_info&series_id=${category_id}`;

    let series;

    try {
      // Wait until the data is fully fetched
      series = await api._get(url);
    } catch (error) {
      console.error("Error fetching series data:", error);
      return [];
    }

    // Check if the series object is valid and contains episodes
    if (!series || typeof series !== "object" || !series.episodes) {
      console.warn("Invalid series data or no episodes found:", series);
      return [];
    }

    // Check if seasonNumber + 1 exists in episodes
    let episodes = series?.episodes[seasonNumber + 1];
    if (!episodes || !Array.isArray(episodes)) {
      console.warn("No episodes found for season:", seasonNumber);
      return [];
    }

    // Convert the episodes to tiles
    let tiles = (leftoverTiles.has(filter) ? leftoverTiles.get(filter) : []).concat(
      convertItemsToTilesSeriesEpisodes(episodes),
    ); // Pass the array of episodes

    let chunks = chunkArray(tiles);
    if (chunks[chunks.length - 1].length < 7) {
      leftoverTiles.set(filter, chunks.pop());
    } else {
      leftoverTiles.delete(filter);
    }

    console.log("eprisdodet", episodes);

    return episodes;
  };
}
