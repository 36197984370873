import { useNavigate } from "@solidjs/router";
import { Text, View, hexColor } from "@lightningtv/solid";
import packageJson from "../../../package.json";
import { Show, createEffect, createMemo, createSignal } from "solid-js";
import Subscriptions from "./components/Subscriptions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Account from "./components/Acoount";
import Language from "./components/Language";
import { Button } from "@lightningtv/solid-ui";
import * as i18n from "@solid-primitives/i18n";
import { changeLanguage, t } from "../../translations/configuration";

const UserInfo = () => {
  let accountRef;
  let subscriptionsRef;
  let languageRef;
  let policyRef;
  let refLanguage;
  let itLang;
  let deLang;
  let frLang;
  let grLang;
  let esLang;

  const navigate = useNavigate();
  const [accountFocus, setAccountFocus] = createSignal(false);
  const [subscriptionFocus, setSubscriptionFocus] = createSignal(false);
  const [languageFocus, setLanguageFocus] = createSignal(false);
  const [privacyPolicyFocus, setPrivacyPolicyFocus] = createSignal(false);

  createEffect(() => {
    accountRef.setFocus();
    setAccountFocus(true);
  });

  return (
    <View
      color={hexColor("#000000")}
      onEscape={() => {
        navigate("/home");
      }}
      width={3000}
    >
      <View
        ref={el => {
          accountRef = el;
        }}
        x={100}
        y={100}
        style={{
          width: 320,
          height: 60,
          borderRadius: 15,
          // color: hexColor('#222222'),
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
          focus: { color: hexColor("#128DD3") },
        }}
        onDown={() => subscriptionsRef.setFocus()}
        onFocus={() => {
          setAccountFocus(true);
          setSubscriptionFocus(false);
          setLanguageFocus(false);
          setPrivacyPolicyFocus(false);
        }}
      >
        <Text x={30} y={18} style={{ fontSize: 25, color: hexColor("#ffffff") }}>
          {t("account")}
        </Text>
      </View>
      <View
        ref={el => {
          subscriptionsRef = el;
        }}
        x={100}
        y={170}
        style={{
          width: 320,
          height: 60,
          borderRadius: 15,
          // color: hexColor('#222222'),
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
          focus: { color: hexColor("#128DD3") },
        }}
        onDown={() => policyRef.setFocus()}
        onUp={() => accountRef.setFocus()}
        onFocus={() => {
          setAccountFocus(false);
          setSubscriptionFocus(true);
          setLanguageFocus(false);
          setPrivacyPolicyFocus(false);
        }}
      >
        <Text x={30} y={18} style={{ fontSize: 25, color: hexColor("#ffffff") }}>
          {t("subscriptions")}
        </Text>
      </View>

      <View
        ref={el => {
          policyRef = el;
        }}
        x={100}
        y={240}
        // y={310}
        style={{
          width: 320,
          height: 60,
          borderRadius: 15,
          //color: hexColor('#222222'),
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
          focus: { color: hexColor("#128DD3") },
        }}
        onUp={() => subscriptionsRef.setFocus()}
        onDown={() => languageRef.setFocus()}
        onFocus={() => {
          setAccountFocus(false);
          setSubscriptionFocus(false);
          setLanguageFocus(false);
          setPrivacyPolicyFocus(true);
        }}
      >
        <Text x={30} y={18} style={{ fontSize: 25, color: hexColor("#ffffff") }}>
          {t("privacyPilicy")}
        </Text>
      </View>

      <View
        ref={el => {
          languageRef = el;
        }}
        x={100}
        y={300}
        style={{
          width: 320,
          height: 60,
          borderRadius: 15,
          //  color: hexColor('#222222'),
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
          focus: { color: hexColor("#128DD3") },
        }}
        onDown={() => policyRef.setFocus()}
        onUp={() => policyRef.setFocus()}
        onRight={() => refLanguage.setFocus()}
        onFocus={() => {
          setAccountFocus(false);
          setSubscriptionFocus(false);
          setLanguageFocus(true);
          setPrivacyPolicyFocus(false);
        }}
      >
        <Text x={30} y={18} style={{ fontSize: 25, color: hexColor("#ffffff") }}>
          {t("languge")}
        </Text>
      </View>
      {/* separation line */}
      <View
        x={500}
        y={30}
        style={{
          borderLeftWidth: 0.5,
          height: 1020,
          width: 3,
          color: hexColor("#ffffff"),
        }}
      />
      {/* show focused components */}
      <Show when={accountFocus()}>
        <Account />
      </Show>
      <Show when={subscriptionFocus()}>
        <Subscriptions />
      </Show>
      <Show when={privacyPolicyFocus()}>
        <PrivacyPolicy />
      </Show>
      <Show when={languageFocus()}>
        <View x={550} y={180} height={2000} width={2000} clipping>
          {/* <Text x={550} style={{ fontSize: 30, color: hexColor("#ffffff") }}>
            {t("chooseLang")}
          </Text> */}
          <Text style={{ fontSize: 35, color: hexColor("#ffffff") }}>{t("chooseLang")}</Text>
          <View y={80}>
            <View
              onLeft={() => languageRef.setFocus()}
              onRight={() => itLang.setFocus()}
              onEnter={() => changeLanguage("en")}
              ref={refLanguage}
              style={{
                borderRadius: 16,
                width: 180,
                height: 130,
                scale: 1,
                zIndex: 2,
                transition: { scale: { duration: 250, easing: "ease-in-out" } },
                border: { width: 0, color: 0x00000000 },
                //focus: { scale: 1.1, border: { color: 0xffffff66, width: 5 } },
                focus: { color: hexColor("#128DD3"), textColor: hexColor("#FFFFFF") },
                color: hexColor("#222222"),
                alignItmes: "center",
              }}
            >
              <View width={156} height={80} x={10} y={10} src={"https://i.ibb.co/7x245bF6/usa.png"} />
              <Text x={65} y={100} style={{ fontSize: 18 }}>
                {t("english")}
              </Text>
            </View>

            <View
              ref={itLang}
              x={200}
              onEnter={() => changeLanguage("it")}
              onLeft={() => refLanguage.setFocus()}
              onRight={() => {
                deLang.setFocus();
              }}
              style={{
                borderRadius: 16,
                width: 180,
                height: 130,
                scale: 1,
                zIndex: 2,
                transition: { scale: { duration: 250, easing: "ease-in-out" } },
                border: { width: 0, color: 0x00000000 },
                //focus: { scale: 1.1, border: { color: 0xffffff66, width: 5 } },
                focus: { color: hexColor("#128DD3"), textColor: hexColor("#FFFFFF") },
                color: hexColor("#222222"),
                alignItmes: "center",
              }}
            >
              <View
                width={156}
                height={80}
                x={10}
                y={10}
                src={"https://i.ibb.co/VYD4hq0t/Flag-of-Italy-1946-2003.png"}
              />
              <Text x={65} y={100} style={{ fontSize: 18 }}>
                {t("italian")}
              </Text>
            </View>

            <View
              ref={deLang}
              x={400}
              onEnter={() => changeLanguage("de")}
              onLeft={() => itLang.setFocus()}
              onRight={() => {
                esLang.setFocus();
              }}
              style={{
                borderRadius: 16,
                width: 180,
                height: 130,
                scale: 1,
                zIndex: 2,
                transition: { scale: { duration: 250, easing: "ease-in-out" } },
                border: { width: 0, color: 0x00000000 },
                //focus: { scale: 1.1, border: { color: 0xffffff66, width: 5 } },
                focus: { color: hexColor("#128DD3"), textColor: hexColor("#FFFFFF") },
                color: hexColor("#222222"),
                alignItmes: "center",
              }}
            >
              <View
                width={156}
                height={80}
                x={10}
                y={10}
                src={"https://i.ibb.co/27mzrq4t/Flag-of-Germany-svg.webp"}
              />
              <Text x={65} y={100} style={{ fontSize: 18 }}>
                German
              </Text>
            </View>

            {/* <View
              ref={grLang}
              x={600}
              onEnter={() => changeLanguage("gr")}
              onLeft={() => deLang.setFocus()}
              onRight={() => {esLang.setFocus()}}
              style={{
                borderRadius: 16,
                width: 180,
                height: 130,
                scale: 1,
                zIndex: 2,
                transition: { scale: { duration: 250, easing: "ease-in-out" } },
                border: { width: 0, color: 0x00000000 },
                //focus: { scale: 1.1, border: { color: 0xffffff66, width: 5 } },
                focus: { color: hexColor("#128DD3"), textColor: hexColor("#FFFFFF") },
                color: hexColor("#222222"),
                alignItmes: "center",
              }}
            >

              <View
                width={156}
                height={80}
                x={10}
                y={10}
                src={
                  "https://i.ibb.co/Pv155PJy/Flag-of-Greece-svg.png"
                }
              />
              <Text x={65} y={100} style={{ fontSize: 18 }}>Greek</Text>
            </View> */}

            <View
              ref={esLang}
              x={600}
              onEnter={() => changeLanguage("es")}
              onLeft={() => deLang.setFocus()}
              onRight={() => {
                frLang.setFocus();
              }}
              style={{
                borderRadius: 16,
                width: 180,
                height: 130,
                scale: 1,
                zIndex: 2,
                transition: { scale: { duration: 250, easing: "ease-in-out" } },
                border: { width: 0, color: 0x00000000 },
                //focus: { scale: 1.1, border: { color: 0xffffff66, width: 5 } },
                focus: { color: hexColor("#128DD3"), textColor: hexColor("#FFFFFF") },
                color: hexColor("#222222"),
                alignItmes: "center",
              }}
            >
              <View
                width={156}
                height={80}
                x={10}
                y={10}
                src={"https://i.ibb.co/0Nds6Fx/Spain-flag-300.png"}
              />
              <Text x={65} y={100} style={{ fontSize: 18 }}>
                Spanish
              </Text>
            </View>

            <View
              ref={frLang}
              x={800}
              onEnter={() => changeLanguage("fr")}
              onLeft={() => esLang.setFocus()}
              style={{
                borderRadius: 16,
                width: 180,
                height: 130,
                scale: 1,
                zIndex: 2,
                transition: { scale: { duration: 250, easing: "ease-in-out" } },
                border: { width: 0, color: 0x00000000 },
                //focus: { scale: 1.1, border: { color: 0xffffff66, width: 5 } },
                focus: { color: hexColor("#128DD3"), textColor: hexColor("#FFFFFF") },
                color: hexColor("#222222"),
                alignItmes: "center",
              }}
            >
              <View
                width={156}
                height={80}
                x={10}
                y={10}
                src={"https://i.ibb.co/Fb5JmryQ/Flag-of-France.png"}
              />
              <Text x={65} y={100} style={{ fontSize: 18 }}>
                French
              </Text>
            </View>
          </View>
        </View>
      </Show>
    </View>
  );
};
export default UserInfo;
