//@ts-ignore
import { createEffect, createSignal, onCleanup, For, onMount, createMemo } from "solid-js";
import { View, Text, hexColor, Show, ElementNode } from "@lightningtv/solid";
import { useNavigate, useParams } from "@solidjs/router";
import styles from "../../styles";
import {
  isVpnMode,
  playListPassword,
  playListUrl,
  playListUsername,
  setGlobalBackground,
  setIsPlaying,
  setIsVpnMode,
} from "../../../state";
import { Button, ButtonContainer, Column, Input, Keyboard, Row } from "@lightningtv/solid-ui";
import { LazyUp } from "@lightningtv/solid/primitives";
import LoadingIndicator from "../loading/loading";
import { fetchData } from "../../api/workers/fetchWorkerManager";

const SearchPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  ////// Search////////////////////////////////////////////
  const [inputValue, setInputValue] = createSignal("");

  const [data2, setData2] = createSignal(null);
  const [data, setData] = createSignal([]);
  const titleSignal = createSignal("");
  const keyEvent = createSignal("");
  const [resetLazyUp, setResetLazyUp] = createSignal(false);

  ////// Search////////////////////////////////////////////
  // const providerCategory = createMemo(() => {
  //   return createInfiniteScroll(
  //     //@ts-ignore
  //     searchMovies(params.filter || "all"),
  //   );
  // });

  // createEffect(() => {
  //   const kevi: any = providerCategory().pages();
  //   if (providerCategory().pages()) {
  //     //@ts-ignore
  //     setData(kevi);
  //   }
  // });

  createEffect(() => {
    const fetchDataFromWorker = async () => {
      const username = playListUsername();
      const password = playListPassword();
      const name = playListUrl();
      const url = `${name}/player_api.php?username=${username}&password=${password}&action=get_vod_streams`;

      try {
        // const newApi = await api._get(url);
        const result = await fetchData(url);

        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataFromWorker();
  });

  onMount(() => {
    setGlobalBackground("#0A1C34");

    const storedData = localStorage.getItem("moviesData");

    if (storedData) {
      setData2(JSON.parse(storedData)); // Load data from localStorage
    }
  });

  ///Search////////////////////////////////////////

  createEffect(() => {
    localStorage.setItem("searchInput", inputValue());
  });

  onMount(() => {
    const storedInput = localStorage.getItem("searchInput");
    if (storedInput) {
      setInputValue(storedInput);
    }
  });

  const [displayedItems, setDisplayedItems] = createSignal([]);

  const filterData = () => {
    if (!titleSignal[0]()) return data();
    return data().filter((item: any) => item.name.toLowerCase().includes(titleSignal[0]().toLowerCase()));
  };

  // Create an effect to update the displayed items whenever `filterData` changes
  createEffect(() => {
    const filtered: any = filterData();
    // Optionally, you can reset the list to empty before setting new data
    setDisplayedItems([]); // Reset list
    setTimeout(() => setDisplayedItems(filtered), 0); // Update list with new data
  });

  let viewRef;

  let row1;

  const formats = {
    uppercase: [
      [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
        {
          title: "Delete",
          size: "md",
          keyId: "delete",
          announce: "delete, button",
        },
      ],
      [
        "Q",
        "W",
        "E",
        "R",
        "T",
        "Y",
        "U",
        "I",
        "O",
        "P",
        {
          title: "#@!",
          size: "md",
          toggle: "symbols",
          announce: "symbol mode, button",
          keyId: "symbols",
        },
      ],
      [
        "A",
        "S",
        "D",
        "F",
        "G",
        "H",
        "J",
        "K",
        "L",
        "@",
        {
          title: "áöû",
          size: "md",
          toggle: "accents",
          announce: "accents, button",
          keyId: "accents",
        },
      ],
      [
        "Z",
        "X",
        "C",
        "V",
        "B",
        "N",
        "M",
        { title: ".", announce: "period, button" },
        { title: "-", announce: "dash, button" },
        { title: "_", announce: "underscore, button" },
        {
          title: "shift",
          size: "md",
          toggle: "default",
          announce: "shift off, button",
          keyId: "shift",
        },
      ],
      [
        { title: ".com", announce: "dot, com", size: "md" },
        { title: ".net", announce: "dot, net", size: "md" },
        { title: ".edu", announce: "dot, edu", size: "md" },
        { title: ".org", announce: "dot, org", size: "md" },
        { title: ".co", announce: "dot, co", size: "md" },
        { title: ".uk", announce: "dot, uk", size: "md" },
      ],
      [
        {
          title: "Clear",
          size: "lg",
          keyId: "clear",
          announce: "clear, button",
        },
        {
          title: "Space",
          size: "xl",
          keyId: "space",
          announce: "space, button",
        },
        {
          title: "Done",
          size: "lg",
          keyId: "done",
          announce: "done, button",
        },
      ],
    ],
    default: [
      [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
        {
          title: "Delete",
          size: "md",
          keyId: "delete",
          announce: "delete, button",
        },
      ],
      [
        "q",
        "w",
        "e",
        "r",
        "t",
        "y",
        "u",
        "i",
        "o",
        "p",
        {
          title: "#@!",
          size: "md",
          toggle: "symbols",
          announce: "symbol mode, button",
          keyId: "symbols",
        },
      ],
      [
        "a",
        "s",
        "d",
        "f",
        "g",
        "h",
        "j",
        "k",
        "l",
        "@",
        {
          title: "áöû",
          size: "md",
          toggle: "accents",
          announce: "accents, button",
          keyId: "accents",
        },
      ],
      [
        "z",
        "x",
        "c",
        "v",
        "b",
        "n",
        "m",
        { title: "_", announce: "underscore, button" },
        { title: ".", announce: "period, button" },
        { title: "-", announce: "dash, button" },
        {
          title: "shift",
          size: "md",
          toggle: "uppercase",
          announce: "shift on, button",
          keyId: "shift",
        },
      ],
      [
        { title: ".com", announce: "dot, com", size: "md" },
        { title: ".net", announce: "dot, net", size: "md" },
        { title: ".edu", announce: "dot, edu", size: "md" },
        { title: ".org", announce: "dot, org", size: "md" },
        { title: ".co", announce: "dot, co", size: "md" },
        { title: ".uk", announce: "dot, uk", size: "md" },
      ],
      [
        {
          title: "Clear",
          size: "lg",
          keyId: "clear",
          announce: "clear, button",
        },
        {
          title: "Space",
          size: "xl",
          keyId: "space",
          announce: "space, button",
        },
        {
          title: "Done",
          size: "lg",
          keyId: "done",
          announce: "done, button",
        },
      ],
    ],
  };

  createEffect(() => {
    titleSignal[0](); // React to search input changes
    setResetLazyUp(false); // Remove the component
    setTimeout(() => setResetLazyUp(true), 0); // Re-add the component in the next tick
  });

  return (
    <View color={hexColor("#000000")}>
      <View
        //   y={390}
        width={1920}
        onEscape={() => {
          navigate(-1);
        }}
        height={1700}
        positionAbsolute={0}
      >
        <View width={1080} x={50} y={100}>
          <Input y={1000} width={1080} titleSignal={titleSignal} keyEvent={keyEvent}></Input>
          <Column autofocus selected={1} scroll={"none"}>
            <View height={100} color={hexColor("#222222")}>
              <Text x={20} y={25} style={{ fontSize: 48 }}>
                {titleSignal[0]()}
              </Text>
            </View>
            {/* @ts-ignore */}
            <Keyboard
              onRight={() => viewRef.setFocus()}
              onEnter={() => {
                console.log("keyEvent", titleSignal[0]());
              }}
              ref={row1}
              formats={formats}
              keySignal={keyEvent}
            />
          </Column>
        </View>
        <View y={100} gap={20}>
          <Show
            when={data().length > 0}
            fallback={
              // <Text x={1350} fontSize={30}>Loading ...</Text>
              <View x={1400}>
                <LoadingIndicator isCategoriesIndicator={false} />
              </View>
            }
          >
            <Show when={resetLazyUp()}>
              {/* @ts-ignore */}
              <LazyUp
                x={250}
                component={Column}
                onLeft={() => row1.setFocus()}
                ref={viewRef}
                scroll="always"
                direction="column"
                upCount={8}
                each={displayedItems()}
              >
                {(item: any) => {
                  return (
                    <View
                      onEnter={() => {
                        console.log(
                          "Navigating to:",
                          `/entity/movie/${item().stream_id}/${item().category_id}`,
                        );
                        navigate(`/entity/movie/${item().stream_id}/${item().category_id}`);
                      }}
                      x={950}
                      style={{
                        borderRadius: 16,
                        width: 630,
                        height: 165,
                        scale: 1,
                        zIndex: 2,
                        color: hexColor("#222222"),
                        focus: { color: hexColor("#128DD3"), textColor: hexColor("#ffffff") },
                        transition: {
                          scale: { duration: 250, easing: "ease-in-out" },
                        },
                        border: { width: 0, color: 0x00000000 },
                      }}
                    >
                      <View
                        src={item().stream_icon}
                        x={10}
                        y={10}
                        style={{ width: 100, height: 145, borderRadius: 10 }}
                      ></View>
                      <Text style={{ fontSize: 27 }} x={135} y={40}>
                        {item().name.length > 33 ? `${item().name.slice(0, 33)}...` : item().name}
                      </Text>
                    </View>
                  );
                }}
              </LazyUp>
            </Show>
          </Show>
        </View>
      </View>
    </View>
  );
};
export default SearchPage;
