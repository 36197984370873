import { Text, View, hexColor } from "@lightningtv/solid";
import { t } from "../../../translations/configuration";
const PrivacyPolicy = (props: any) => {
  const termOne = `${t("one")}`;

  const termTwo = `${t("two")}`;
  const termThree = `${t("three")}`;
  const termFour = `${t("four")}`;
  const termFive = `${t("five")}`;
  const termSix = `${t("six")}`;

  return (
    <View ref={props.ref} x={550} y={180} height={2000} width={2000} clipping>
      <Text x={350} style={{ fontSize: 30, color: hexColor("#ffffff") }}>
        {t("orbixUserAgreement")}
      </Text>
      <Text x={300} y={80} style={{ fontSize: 23, color: hexColor("#ffffff") }}>
        {t("beforeyouProcc")}
      </Text>
      <Text x={10} y={150} style={textStyle}>
        {termOne}
      </Text>
      <Text x={10} y={270} style={textStyle}>
        {termTwo}
      </Text>
      <Text x={10} y={360} style={textStyle}>
        {termThree}
      </Text>
      <Text x={10} y={445} style={textStyle}>
        {termFour}
      </Text>
      <Text x={10} y={560} style={textStyle}>
        {termFive}
      </Text>
      <Text x={10} y={640} style={textStyle}>
        {termSix}
      </Text>
    </View>
  );
};
const textStyle = {
  fontSize: 23,
  color: hexColor("#ffffff"),
  fontWeight: 400,
  lineHeight: 32,
  width: 1300,
  contain: "width",
};
export default PrivacyPolicy;
