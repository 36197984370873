import { createSignal, createEffect, createMemo, onCleanup, Show } from "solid-js";
import { For } from "solid-js";
import { Text, View, hexColor, setActiveElement } from "@lightningtv/solid";
import { Button, ButtonContainer, ProgressBar } from "@lightningtv/solid-ui";
import { useParams, useLocation } from "@solidjs/router";
import {
  channelMenu,
  isBuffering,
  liveTvStreamID,
  playListPassword,
  playListUrl,
  playListUsername,
  setChannelMenu,
  setGlobalBackground,
  setGlobalStream,
  setGlobalStreamType,
  setIsBuffering,
  setIsPlaying,
  setLastStream,
  setLiveTvStreamID,
  setPreviewToNotShow,
} from "../../../state";
import axios from "axios";
import { useNavigate } from "@solidjs/router";
import OSD from "./Osd";
import videoPlayer from "../../video";
// import videoPlayerPreview from "../../videoPreview";
import LoadingIndicator from "../loading/loading";
import { Buffer } from "buffer";
import ChannelMenu from "./ChannelMenu";

const LiveTvPage = () => {
  const navigate = useNavigate();
  const [data, setData] = createSignal<any>([]);
  const [liveChannel, setLiveChannel] = createSignal<any>({});
  const [currentIndex, setCurrentIndex] = createSignal(0);
  // const [channelMenu, setChannelMenu] = createSignal(false);
  const [currentStream, setCurrentStream] = createSignal();
  const [isVideoPlaying, setIsVideoPlaying] = createSignal(false);
  const [showOSD, setShowOSD] = createSignal(false);
  const [osdQueue, setOsdQueue] = createSignal<any>([]);
  const [isFromSearch, setIsFromSearch] = createSignal(null);
  const [listOfFav, setListOfFav] = createSignal<any>({});
  const [isFavChannel, setIsFavChannel] = createSignal(false);

  let timeoutId;

  const location = useLocation();
  console.log("Current path:", location.pathname);

  function getQueryParams(search) {
    return new URLSearchParams(search);
  }

  createEffect(() => {
    const params: any = getQueryParams(location.search);
    const num = parseInt(params.get("num"), 10);
    const isTrue: any = params.get("isTrue") === "true";
    setIsFromSearch(isTrue);

    const result: any = data().find((stream: any) => stream.stream_id === num);

    if (result) {
      console.log("Stream found:", result);
      const updatetNumber = result.num;
      setCurrentStream(result.stream_id);
      setCurrentIndex(updatetNumber - 1);
    } else {
      console.log("Stream not found");
    }
  });

  createEffect(() => {
    const username = playListUsername();
    const password = playListPassword();
    const playList = playListUrl();
    const vpnStatus = localStorage.getItem("vpn");
    const proxyUrl = localStorage.getItem("proxy_url");

    let apiUrl = `${playList}/player_api.php?username=${username}&password=${password}&action=get_live_streams`;

    // If VPN is on and proxy_url exists, buffer the API URL and use proxy
    if (vpnStatus === "on" && proxyUrl) {
      const bufferedUrl = Buffer.from(apiUrl, "utf-8").toString("base64");
      apiUrl = `${proxyUrl}${bufferedUrl}`;
    }

    console.log("api urlll", apiUrl);

    axios
      .get(apiUrl)
      .then(response => setData(response.data))
      .catch(error => console.error(error));
  });

  createEffect(() => {
    const index = currentIndex();
    const channelData = data();

    if (channelData.length > 0 && index >= 0 && index < channelData.length) {
      setLiveChannel(channelData[index]);
      console.log("Live Channel Updated: ", channelData[index]);
    }
  });

  const incrementIndex = async () => {
    const newIndex = currentIndex() + 1;

    if (newIndex < data().length) {
      // Update the current index and live channel immediately
      setCurrentIndex(newIndex);
      setLiveChannel(data()[newIndex]);

      // Add the new channel number to the OSD queue
      //@ts-ignore
      setOsdQueue(prevQueue => [...prevQueue, data()[newIndex].num]); // Add current channel number

      // Show the OSD for the current channel
      setShowOSD(true); // Show OSD immediately

      // Load the new stream in the background
      loadNewStream(data()[newIndex].stream_id);

      setIsBuffering(true);

      // Reset OSD timeout to hide after a duration
      resetOSDTimeout();
    }
  };

  const decrementIndex = async () => {
    const newIndex = currentIndex() - 1;
    if (newIndex >= 0) {
      // Update the current index and live channel immediately
      setCurrentIndex(newIndex);
      setLiveChannel(data()[newIndex]);

      // Add the new channel number to the OSD queue
      setOsdQueue(prevQueue => [...prevQueue, data()[newIndex].num]); // Add current channel number

      // Show the OSD for the current channel
      setShowOSD(true); // Show OSD immediately

      // Load the new stream in the background
      loadNewStream(data()[newIndex].stream_id);
      setIsBuffering(true);

      // Reset OSD timeout to hide after a duration
      resetOSDTimeout();
    }
  };

  createEffect(() => {
    setShowOSD(true);
    resetOSDTimeout();

    // if (osdQueue().length > 0) {
    //   const currentChannel = osdQueue()[osdQueue().length - 1];
    //   setShowOSD(true);
    //   console.log(`Current Channel: ${currentChannel}`);
    // }
  });

  const loadNewStream = async streamId => {
    // Stop the current stream if necessary
    if (videoPlayer) {
      await videoPlayer.destroy(); // Consider removing this if you want to allow multiple streams to load simultaneously
    }

    // Update the current stream with the new stream ID
    setCurrentStream(streamId);

    setLastStream(streamId);

    // Play the new stream
    const streamUrl = `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/${streamId}.ts`;

    const streamUrlProxy = Buffer.from(
      `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/` + streamId + ".ts",
      "utf-8",
    ).toString("base64");

    if (localStorage.getItem("vpn") == "on") {
      if (localStorage.getItem("proxy_url")) {
        await playCurrentStream(localStorage.getItem("proxy_url") + streamUrlProxy);
      } else {
        await playCurrentStream(streamUrl);
      }
    } else {
      await playCurrentStream(streamUrl);
    }
  };

  const playCurrentStream = async streamUrl => {
    try {
      // await videoPlayer.open(streamUrl);
      videoPlayer.destroy();
      let context = "background";

      // videoPlayer.play(streamUrl , 'preview');
      videoPlayer
        .playVideo(streamUrl, context)
        .then(() => {
          console.log("Video is now playing.");
        })
        .catch(error => {
          console.error("Error playing video:", error);
        });
      // videoPlayer.play(); // Start playing
      setIsVideoPlaying(true);
      console.log("Video is now playing.");
    } catch (error) {
      console.error("Error playing video:", error);
      setIsVideoPlaying(false);
    }
  };

  function onCustomEscape() {
    setLiveTvStreamID(null);
    setIsBuffering(false);

    setPreviewToNotShow(true);
    videoPlayer.setPreviewScreen();
    navigate("/channel-menu");
  }

  async function onEnterTrailer(context: any) {
    const streamOfID = currentStream();
    const videoUrl = `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/${streamOfID}.ts`;
    const streamUrlProxy = Buffer.from(
      `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/` + streamOfID + ".ts",
      "utf-8",
    ).toString("base64");
    // const videoUrl = `http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4`;

    videoPlayer
      .playVideo(
        localStorage.getItem("vpn") == "on"
          ? localStorage.getItem("proxy_url")
            ? localStorage.getItem("proxy_url") + streamUrlProxy
            : videoUrl
          : videoUrl,
        context,
      )
      .then(() => {
        console.log("Video is now playing.");
        setIsBuffering(false);
        setIsVideoPlaying(true);
      })
      .catch(error => {
        console.error("Error playing video:", error);
        setIsVideoPlaying(false);
      });
  }

  createEffect(() => {
    const current = currentStream();

    if (current) {
      if (isFromSearch() === true) {
        onEnterTrailer("background");
      }
    }
  });

  const resetOSDTimeout = () => {
    clearTimeout(timeoutId);

    // Reset the OSD visibility timer
    timeoutId = setTimeout(() => {
      setShowOSD(false);
      setOsdQueue([]); // Clear the queue after timeout
    }, 6000);
  };

  // Cleanup timeout on component unmount
  onCleanup(() => {
    clearTimeout(timeoutId);
  });

  onCleanup(() => {
    clearTimeout(timeoutId);
  });

  createEffect(() => {
    if (liveChannel() && data() && data().length > 0) {
      resetOSDTimeout();
    }
  });

  let channelMenuRef;

  const addToFavorites = (channel: any) => {
    console.log("channel", channel.data.stream_id);

    const existingChannels = localStorage.getItem("@favoriteChannels");
    let existingChannelsArray: any = existingChannels ? JSON.parse(existingChannels) : [];

    const isChannelExist = existingChannelsArray.some(
      (existingChannel: any) => existingChannel.data.stream_id === channel.data.stream_id,
    );

    if (!isChannelExist) {
      existingChannelsArray.push(channel);
    } else {
      existingChannelsArray = existingChannelsArray.filter(
        (existingChannel: any) => existingChannel.data.stream_id !== channel.data.stream_id,
      );
    }

    localStorage.setItem("@favoriteChannels", JSON.stringify(existingChannelsArray));

    console.log("keshtu futet ne fund", JSON.stringify(existingChannelsArray));

    setListOfFav(existingChannelsArray);

    setIsFavChannel(
      existingChannelsArray.some((favChannel: any) => favChannel.data.stream_id === channel.data.stream_id),
    );
  };

  // createEffect(() => {
  //     const existingChannels = localStorage.getItem("@favoriteChannels");
  //     let existingChannelsArray: any = existingChannels ? JSON.parse(existingChannels) : [];

  //     setListOfFav(existingChannelsArray);

  //     setIsFavChannel(
  //         existingChannelsArray.some((favChannel: any) => favChannel.data.stream_id === props.channel.stream_id)
  //     );
  // }, [props.channel.stream_id]);

  // const isFavorited = () =>
  //     listOfFav().some((favChannel: any) => favChannel.data.stream_id === props.data.stream_id);

  // const channelObject = {
  //     data: {
  //         stream_id: props.channel.stream_id,
  //     },
  // };

  const channelObject = {
    src: "http://picon.koolmedia.live/images/sceglicanale.jpg",
    tileSrc: "http://picon.koolmedia.live/images/sceglicanale.jpg",
    backdrop: "w185",
    href: "/entity/live/362210",
    shortTitle: "----Italia----",
    title: "----Italia----",
    data: {
      num: 1,
      name: "----Italia----",
      stream_type: "live",
      stream_id: 362210,
      stream_icon: "http://picon.koolmedia.live/images/sceglicanale.jpg",
      epg_channel_id: "",
      added: "0",
      is_adult: 0,
      category_id: "501",
      category_ids: [501],
      custom_sid: ":64:0:0:0:0:0:0:0:0:",
      tv_archive: 0,
      direct_source: "http://ssd3.italia-90.com:8080/images/scegliuncanale.mp4",
      tv_archive_duration: 0,
    },
    entityInfo: {
      type: "live",
      id: 362210,
    },
    heroContent: {
      title: "----Italia----",
    },
    index: 0,
  };

  createEffect(() => {
    console.log("boliiii 2", liveChannel());
  }, [liveChannel()]);

  return (
    <View
      onDown={() => {
        decrementIndex();
      }}
      onUp={() => {
        incrementIndex();
      }}
      onEscape={() => {
        onCustomEscape();
      }}
      autofocus
      onEnter={() => {
        console.log("kevi");
      }}
    >
      {/* <Show when={channelMenu()}>
        <ChannelMenu isFromLiveTv={true} />
      </Show> */}

      <Show when={liveChannel() && data() && data().length > 0 && showOSD()}>
        <OSD channel={liveChannel()} />
        {/* <ButtonContainer x={60} y={220} width={200} height={70} autofocus={true} borderRadius={20} style={{ opacity: 0.5, zIndex: 999, color: "0x366ba8", focus: { color: "0x366ba8" } }}
          onEnter={() => {
            console.log('kevi');
            // setIsFavChannel(isFavorited());
            addToFavorites(channelObject);
          }}><Text fontSize={22}> {isFavChannel() ? "Remove from Favorites" : "Add to Favorites"}</Text></ButtonContainer> */}
      </Show>

      <Show when={isBuffering()}>
        <View x={800} y={400}>
          <LoadingIndicator />
        </View>
      </Show>
    </View>
  );
};

export default LiveTvPage;
