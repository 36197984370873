import api from "..";
import { playListPassword, playListUrl, playListUsername } from "../../../state";
import { chunkArrayChannel, convertItemsToTilesChannel } from "../formatters/ItemFormatter";

const cache = new Map<string, Promise<any>>();
const leftoverTiles = new Map<string, any[]>();

export default function (filter: string, category_id: string) {
  return (pageIndex: number): Promise<any> => {
    const username = playListUsername();
    const password = playListPassword();
    const cacheKey = `${username}_${category_id}`; // Use a unique key for each user and category

    const url = `/player_api.php?username=${username}&password=${password}&action=get_live_streams&category_id=${category_id}`;
    const favoriteChannels = localStorage.getItem("@favoriteChannels");

    console.log("keto jane favorite", favoriteChannels);

    let favoriteChannelsArray: any = favoriteChannels ? JSON.parse(favoriteChannels) : [];

    if (category_id === "99999") {
      const chunks = chunkArrayChannel(favoriteChannelsArray);
      console.log("hyri te 999");

      return Promise.resolve(chunks);
    }

    if (cache.has(cacheKey)) {
      return cache.get(cacheKey);
    }

    const result = api._get(url).then(channels => {
      let results = channels;
      const tileKey = `${filter}_${category_id}`;
      const existingTiles = leftoverTiles.get(tileKey) || [];

      const tiles = existingTiles.concat(convertItemsToTilesChannel(results));
      const chunks = chunkArrayChannel(tiles);

      if (chunks[chunks?.length - 1]?.length < 7) {
        leftoverTiles.set(tileKey, chunks.pop() || []);
      } else {
        leftoverTiles.delete(tileKey);
      }

      return chunks;
    });

    console.log("results", result);

    cache.set(cacheKey, result);
    return result;
  };
}
