import {
  createEffect,
  onCleanup,
  createMemo,
  on,
  createSignal,
  createSelector,
  onMount,
  Show,
} from "solid-js";
//@ts-ignore
import { ElementNode, IntrinsicTextNodeStyleProps, View, hexColor, deg2Rad, Text } from "@lightningtv/solid";
import { useNavigate } from "@solidjs/router";
import { useParams, useLocation } from "@solidjs/router";
import {
  isBuffering,
  playListPassword,
  playListUrl,
  playListUsername,
  setIsBuffering,
  setPreviewToNotShow,
} from "../../../state";
import videoPlayer from "../../video";
import { ProgressBar } from "@lightningtv/solid-ui";
import LoadingIndicator from "../loading/loading";
import { Buffer } from "buffer";

const Player = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = createSignal(null);
  const [isVideoPlaying, setIsVideoPlaying] = createSignal(false);
  const [videoDuration, setVideoDuration] = createSignal(0);
  const [currentTime, setCurrentTime] = createSignal(0);
  const [contentType, setContentType] = createSignal("");
  const [nameMovie, setNameMovie] = createSignal("");
  const [progress, setProgress] = createSignal(0);
  const [timeSeconds, setTimeSeconds] = createSignal(0);
  const [extencion, setExtencion] = createSignal("");
  const [storedSeekTime, setStoredSeekTime] = createSignal(0);

  const location = useLocation();
  let updateInterval;

  const [controlsVisible, setControlsVisible] = createSignal(true); // Control visibility state
  let controlsTimeout;

  const showControls = () => {
    setControlsVisible(true);
    resetControlsTimeout();
  };

  const hideControls = () => {
    setControlsVisible(false);
  };

  const resetControlsTimeout = () => {
    clearTimeout(controlsTimeout);
    controlsTimeout = setTimeout(hideControls, 6000); // Hide controls after 6 seconds
  };

  createEffect(() => {
    const params = new URLSearchParams(location.search);
    const num: any = params.get("str");
    const type = params.get("type"); // Get the content type from the query
    const name = params.get("name") ? decodeURIComponent(params.get("name")!) : "";
    const exc = params.get("extc");
    const storedSeek = params.get("seek");

    console.log("ketuajn te gjitha", num, type, exc, name, storedSeek);

    // Ensure currentIndex is set initially
    if (num !== null) {
      setCurrentIndex(num);
    }
    // Set content type (serie or movie)
    if (type) {
      setContentType(type);
    }
    if (name) {
      setNameMovie(name);
    }
    if (exc) {
      setExtencion(exc);
    }
    if (storedSeek) {
      setStoredSeekTime(storedSeek);
    }
  });

  createEffect(() => {
    const streamOfID = currentIndex();
    const type = contentType();
    const exce = extencion();
    const videoUrl = `${playListUrl()}/${type}/${playListUsername()}/${playListPassword()}/${streamOfID}.${exce}`;
    // const videoUrl = `http://vpnsurface.com:80/series/fons_tq5qfx/w7vjwhj9/347677.mp4`;

    console.log("This is video url", videoUrl);

    const streamUrlProxy = Buffer.from(
      `${playListUrl()}/${type}/${playListUsername()}/${playListPassword()}/` + streamOfID + `.${exce}`,
      "utf-8",
    ).toString("base64");

    setIsBuffering(true);
    setPreviewToNotShow(false);
    videoPlayer.setFullScreen();

    videoPlayer
      .playVideo(
        localStorage.getItem("vpn") == "on"
          ? localStorage.getItem("proxy_url")
            ? localStorage.getItem("proxy_url") + streamUrlProxy
            : videoUrl
          : videoUrl,

        "background",
      )
      .then(() => {
        console.log("Video is now playing.");
        setIsVideoPlaying(true);
        resetControlsTimeout();
        setIsBuffering(false);
        const seekTo = Number(storedSeekTime());

        if (!isNaN(seekTo) && seekTo > 0) {
          videoPlayer.seek(seekTo);
        }

        const getVideoDuration = async () => {
          try {
            const duration = await videoPlayer.getDuration();
            console.log("Video Duration brenda:", duration);
            setVideoDuration(duration);
          } catch (error) {
            console.error("Error getting video duration:", error);
          }
        };

        getVideoDuration();
        const timeUpdateHandler = () => {
          if (location.pathname.startsWith("/player")) {
            setTimeSeconds(videoPlayer.getCurrentTime());
            console.log("Current Time:", videoPlayer.getCurrentTime());
          }
        };

        updateInterval = setInterval(timeUpdateHandler, 500);
      })
      .catch(error => {
        console.error("Error playing video:", error);
        setIsVideoPlaying(false);
      });
  });

  const onCustomEscape = () => {
    const currentTime = videoPlayer.getCurrentTime();
    const streamOfID = currentIndex();
    const type = contentType();
    const exce = extencion();
    const videoUrl = `${playListUrl()}/${type}/${playListUsername()}/${playListPassword()}/${streamOfID}.${exce}`;
    const duration = videoDuration();

    if (currentTime === duration) {
      console.log("mbaroi filmi");
    } else {
      localStorage.setItem("vod_resume", JSON.stringify({ videoUrl, currentTime }));
    }

    videoPlayer.destroy();
    setPreviewToNotShow(true);

    if (updateInterval) {
      clearInterval(updateInterval);
      console.log("Interval cleared with ID:", updateInterval);
      updateInterval = null;
      setTimeout(() => {
        navigate(-1);
        if (videoPlayer) {
          videoPlayer.destroy();
        }
      }, 100);
    } else {
      setTimeout(() => {
        navigate(-1);
        if (videoPlayer) {
          videoPlayer.destroy();
        }
      }, 100);
    }
  };

  // Toggle play/pause state
  const togglePlayPause = () => {
    if (isVideoPlaying()) {
      videoPlayer.pause();
    } else {
      videoPlayer.resume();
    }
    setIsVideoPlaying(!isVideoPlaying());
    showControls();
  };

  let seekAccumulatedTime = 0;
  let seekTimeout: any;

  const seekTime = seconds => {
    try {
      const current = currentTime();
      const duration = videoDuration();

      console.log("seconds", seconds);
      console.log("Current time", current);
      console.log("Duration time", duration);

      // Validate current and duration values
      if (!isFinite(current) || !isFinite(duration)) {
        console.error("Invalid current time or video duration.");
        return;
      }

      // Accumulate the seek time
      seekAccumulatedTime += seconds * 1000;

      const progressTime = current + seekAccumulatedTime;
      const clampedTime = Math.max(0, Math.min(progressTime, duration));

      const finalProgress = duration > 0 ? clampedTime / duration : 0;

      setProgress(finalProgress);
      setTimeSeconds(progressTime);

      // Clear previous timeout
      if (seekTimeout) {
        clearTimeout(seekTimeout);
      }

      // Set timeout to apply the seek after a delay
      seekTimeout = setTimeout(() => {
        const newTime = current + seekAccumulatedTime;
        const clampedTime = Math.max(0, Math.min(newTime, duration));
        console.log("Seeking to clampedTime:", clampedTime);

        if (isFinite(clampedTime)) {
          videoPlayer.seek(clampedTime);
          setCurrentTime(clampedTime);
        } else {
          console.error("Player is not ready or clampedTime is invalid.");
        }

        // Reset accumulated time after seeking
        seekAccumulatedTime = 0;
      }, 700); // Adjust delay time if needed
    } catch (error: any) {
      console.error("Error in seekTime function:", error.message);
    }
  };

  const onKeyPressActions = () => {
    showControls(); // Show controls when a key is pressed
    resetControlsTimeout(); // Reset timeout to hide controls
  };

  function formatTime(ms) {
    let totalSeconds = Math.floor(ms / 1000);
    let hours: any = Math.floor(totalSeconds / 3600);
    let minutes: any = Math.floor((totalSeconds % 3600) / 60);
    let seconds: any = totalSeconds % 60;
    // Pad hours, minutes, and seconds with leading zeros if needed
    hours = String(hours).padStart(2, "0");
    minutes = String(minutes).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  onMount(() => {
    console.log("Component Mounted");

    // Cleanup function runs when the component unmounts
    onCleanup(() => {
      const currentTime = videoPlayer.getCurrentTime();
      const streamOfID = currentIndex();
      const type = contentType();
      const exce = extencion();
      const videoUrl = `${playListUrl()}/${type}/${playListUsername()}/${playListPassword()}/${streamOfID}.${exce}`;
      const duration = videoDuration();

      if (currentTime === duration) {
        console.log("mbaroi filmi");
      } else {
        localStorage.setItem("vod_resume", JSON.stringify({ videoUrl, currentTime }));
      }
    });
  });

  return (
    <View>
      <View
        onEscape={() => {
          onCustomEscape();
        }}
        onEnter={() => {
          togglePlayPause();
        }}
        onUp={() => {
          onKeyPressActions();
        }}
        onDown={() => {
          onKeyPressActions();
        }}
        onRight={() => {
          seekTime(15);
          onKeyPressActions();
        }}
        onLeft={() => {
          seekTime(-15);
          onKeyPressActions();
        }}
        autofocus
      >
        <Show when={isBuffering()}>
          <View x={800} y={400}>
            <LoadingIndicator />
          </View>
        </Show>
        <Show when={controlsVisible()}>
          <View
            src={"https://i.ibb.co/sHw6rPz/Pngtree-black-gradient-vertical-gradient-element-5441580.png"}
          ></View>
          <View
            src={
              "https://i.ibb.co/NSvf1HZ/Lovepik-com-380148632-black-transparent-vertical-linear-gradient-gradient-element-transparency-black.png"
            }
          ></View>

          <Text style={{ fontSize: 62 }} x={50} y={40}>
            {nameMovie()}
          </Text>

          <Text y={950} x={1600} style={{ fontSize: 24 }}>
            {`${formatTime(timeSeconds())} / ${formatTime(videoDuration())}`}
          </Text>
          <ProgressBar
            progress={progress()}
            borderRadius={5}
            height={10}
            width={1650}
            y={1000}
            x={100}
            value={0}
            progressRadius={100}
          />

          <Show when={!isBuffering()}>
            <View
              src={
                isVideoPlaying()
                  ? "https://i.ibb.co/MnqnLDD/pause.png"
                  : "https://i.ibb.co/ZWM6s0t/play-button-arrowhead.png"
              }
              x={870}
              y={500}
              width={50}
              height={50}
            />
          </Show>
        </Show>
      </View>
    </View>
  );
};

export default Player;
