//@ts-ignore
import { createEffect, createSignal, onCleanup, For, onMount, createMemo } from "solid-js";
import { View, Text, hexColor, Show, ElementNode } from "@lightningtv/solid";
import { useNavigate, useParams } from "@solidjs/router";
import styles from "../../styles";
import {
  isVpnMode,
  playListPassword,
  playListUrl,
  playListUsername,
  setGlobalBackground,
  setIsPlaying,
  setIsVpnMode,
  setPreviewToNotShow,
} from "../../../state";
import { Button, ButtonContainer, Column, Input, Keyboard, Row } from "@lightningtv/solid-ui";
import axios from "axios";
import { assertTruthy } from "@lightningjs/renderer/utils";
import { createInfiniteScroll } from "../../components/pagination";
import searchMovies from "../../api/providers/searchMovies";
import { LazyUp } from "@lightningtv/solid/primitives";
import searchLive from "../../api/providers/searchLive";
import LoadingIndicator from "../loading/loading";
import videoPlayer from "../../video";

const SearchLive = () => {
  const formats = {
    uppercase: [
      [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
        {
          title: "Delete",
          size: "md",
          keyId: "delete",
          announce: "delete, button",
        },
      ],
      [
        "Q",
        "W",
        "E",
        "R",
        "T",
        "Y",
        "U",
        "I",
        "O",
        "P",
        {
          title: "#@!",
          size: "md",
          toggle: "symbols",
          announce: "symbol mode, button",
          keyId: "symbols",
        },
      ],
      [
        "A",
        "S",
        "D",
        "F",
        "G",
        "H",
        "J",
        "K",
        "L",
        "@",
        {
          title: "áöû",
          size: "md",
          toggle: "accents",
          announce: "accents, button",
          keyId: "accents",
        },
      ],
      [
        "Z",
        "X",
        "C",
        "V",
        "B",
        "N",
        "M",
        { title: ".", announce: "period, button" },
        { title: "-", announce: "dash, button" },
        { title: "_", announce: "underscore, button" },
        {
          title: "shift",
          size: "md",
          toggle: "default",
          announce: "shift off, button",
          keyId: "shift",
        },
      ],
      [
        { title: ".com", announce: "dot, com", size: "md" },
        { title: ".net", announce: "dot, net", size: "md" },
        { title: ".edu", announce: "dot, edu", size: "md" },
        { title: ".org", announce: "dot, org", size: "md" },
        { title: ".co", announce: "dot, co", size: "md" },
        { title: ".uk", announce: "dot, uk", size: "md" },
      ],
      [
        {
          title: "Clear",
          size: "lg",
          keyId: "clear",
          announce: "clear, button",
        },
        {
          title: "Space",
          size: "xl",
          keyId: "space",
          announce: "space, button",
        },
        {
          title: "Done",
          size: "lg",
          keyId: "done",
          announce: "done, button",
        },
      ],
    ],
    default: [
      [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
        {
          title: "Delete",
          size: "md",
          keyId: "delete",
          announce: "delete, button",
        },
      ],
      [
        "q",
        "w",
        "e",
        "r",
        "t",
        "y",
        "u",
        "i",
        "o",
        "p",
        {
          title: "#@!",
          size: "md",
          toggle: "symbols",
          announce: "symbol mode, button",
          keyId: "symbols",
        },
      ],
      [
        "a",
        "s",
        "d",
        "f",
        "g",
        "h",
        "j",
        "k",
        "l",
        "@",
        {
          title: "áöû",
          size: "md",
          toggle: "accents",
          announce: "accents, button",
          keyId: "accents",
        },
      ],
      [
        "z",
        "x",
        "c",
        "v",
        "b",
        "n",
        "m",
        { title: "_", announce: "underscore, button" },
        { title: ".", announce: "period, button" },
        { title: "-", announce: "dash, button" },
        {
          title: "shift",
          size: "md",
          toggle: "uppercase",
          announce: "shift on, button",
          keyId: "shift",
        },
      ],
      [
        { title: ".com", announce: "dot, com", size: "md" },
        { title: ".net", announce: "dot, net", size: "md" },
        { title: ".edu", announce: "dot, edu", size: "md" },
        { title: ".org", announce: "dot, org", size: "md" },
        { title: ".co", announce: "dot, co", size: "md" },
        { title: ".uk", announce: "dot, uk", size: "md" },
      ],
      [
        {
          title: "Clear",
          size: "lg",
          keyId: "clear",
          announce: "clear, button",
        },
        {
          title: "Space",
          size: "xl",
          keyId: "space",
          announce: "space, button",
        },
        {
          title: "Done",
          size: "lg",
          keyId: "done",
          announce: "done, button",
        },
      ],
    ],
  };

  const params = useParams();
  const navigate = useNavigate();

  ////// Search////////////////////////////////////////////
  const [inputValue, setInputValue] = createSignal("");
  const [data2, setData2] = createSignal(null);
  const [data, setData] = createSignal(null);

  ////// Search////////////////////////////////////////////
  const providerCategory = createMemo(() => {
    return createInfiniteScroll(
      //@ts-ignore
      searchLive(params.filter || "all"),
    );
  });

  createEffect(() => {
    const kevi: any = providerCategory().pages();
    if (providerCategory().pages()) {
      //@ts-ignore
      setData(kevi);
    }
  });

  onMount(() => {
    setGlobalBackground("#0A1C34");

    const storedData = localStorage.getItem("moviesData");

    if (storedData) {
      // console.log("Loaded data from localStorage", JSON.parse(storedData));
      setData2(JSON.parse(storedData)); // Load data from localStorage
    }
  });

  ///Search////////////////////////////////////////

  createEffect(() => {
    localStorage.setItem("searchInput", inputValue());
  });

  onMount(() => {
    const storedInput = localStorage.getItem("searchInput");
    if (storedInput) {
      setInputValue(storedInput);
    }
  });

  const [displayedItems, setDisplayedItems] = createSignal([]);

  const filterData = () => {
    if (!titleSignal[0]()) return providerCategory().pages();
    return providerCategory()
      .pages()
      .filter((item: any) => item.title.toLowerCase().includes(titleSignal[0]().toLowerCase()));
  };

  // Create an effect to update the displayed items whenever `filterData` changes
  createEffect(() => {
    if (videoPlayer) {
      videoPlayer.destroy();
    }
    const filtered: any = filterData();
    // Optionally, you can reset the list to empty before setting new data
    setDisplayedItems([]); // Reset list
    setTimeout(() => setDisplayedItems(filtered), 0); // Update list with new data
  });

  let viewRef;

  let row1;

  const titleSignal = createSignal("");
  const keyEvent = createSignal("");
  const [resetLazyUp, setResetLazyUp] = createSignal(false);

  createEffect(() => {
    titleSignal[0](); // React to search input changes
    setResetLazyUp(false); // Remove the component
    setTimeout(() => setResetLazyUp(true), 0); // Re-add the component in the next tick
  });

  return (
    <View color={hexColor("#000000")}>
      <View
        width={1920}
        onEscape={() => {
          navigate(-1);
        }}
        height={1700}
        positionAbsolute={0}
      >
        <View width={1080} x={50} y={100}>
          <Input y={1000} width={1080} titleSignal={titleSignal} keyEvent={keyEvent}></Input>
          <Column autofocus selected={1} scroll={"none"}>
            <View height={100} color={hexColor("#222222")}>
              <Text x={20} y={25} style={{ fontSize: 48 }}>
                {titleSignal[0]()}
              </Text>
            </View>
            {/* @ts-ignore */}
            <Keyboard
              onRight={() => viewRef.setFocus()}
              onEnter={() => {
                console.log("keyEvent", titleSignal[0]());
              }}
              ref={row1}
              formats={formats}
              keySignal={keyEvent}
            />
          </Column>
        </View>
        <View y={100} gap={20}>
          <Show
            when={providerCategory().pages().length > 0}
            fallback={
              // <Text x={1350} fontSize={30}>Loading ...</Text>
              <View x={1400}>
                <LoadingIndicator isCategoriesIndicator={false} />
              </View>
            }
          >
            <Show when={resetLazyUp()}>
              {/* @ts-ignore */}
              <LazyUp
                x={250}
                component={Column}
                onLeft={() => row1.setFocus()}
                ref={viewRef}
                scroll="always"
                direction="column"
                upCount={8}
                each={displayedItems()}
                selected={0}
              >
                {(item: any) => (
                  <View
                    onEnter={() => {
                      // console.log("Item:", item()); // Log the item here
                      setPreviewToNotShow(false);
                      navigate(`/livetv?num=${item().stream_id}&isTrue=true`);
                    }}
                    x={950}
                    style={{
                      borderRadius: 15,
                      border: { width: 0, color: hexColor("#45617a") },
                      color: hexColor("#222222"),
                      focus: { color: hexColor("#128DD3"), textColor: hexColor("#ffffff") },
                      transition: {
                        scale: { duration: 250, easing: "ease-in-out" },
                      },
                      height: 180,
                      width: 650,
                      justifyContent: "flexStart",
                      alignItems: "flexStart",
                    }}
                  >
                    <View
                      src={item().image_stream}
                      x={10}
                      y={10}
                      style={{ width: 250, height: 160, borderRadius: 10 }}
                    ></View>
                    {/* <Text style={{ fontSize: 32 }} x={300} y={75}>
                    {item().title}
                  </Text> */}
                    <Text style={{ fontSize: 27 }} x={300} y={75}>
                      {item().title.length > 33 ? `${item().title.slice(0, 33)}...` : item().title}
                    </Text>
                  </View>
                )}
              </LazyUp>
            </Show>
          </Show>
        </View>
      </View>
    </View>
  );
};
export default SearchLive;
