const dict = {
  chooseLang: "Choose language",

  //Licence
  instructions: "Instructions",
  step1: "Step 1: Scan the QR Code",
  locateqr: "Locate the QR code on your right.",
  useSmartphone:
    "Use your smartphone's camera or a QR code scanner app to scan it. This will take you to the next step.",
  step2: "Step 2: Enter Playlist Information",
  afterScan: "After scanning the QR code, you'll be prompted to enter your playlist information.",
  makeSure: "Make sure to fill in all the required fields.",
  step3: "Step 3: Return to the App",
  onceYou:
    "Once you've entered your playlist details, you can go back to the app and continue your experience.",
  refresh: "REFRESH",
  playlist: "Playlist",
  bigPlaylist: "PLAYLISTS",
  noLicence: "You don't have a Licence.",
  scanLicence: "Scan to buy licence.",
  noPlaylist: "You don't have any playlist",
  privacyBig: "PRIVACY MODE",
  scanToAddBig: "SCAN TO ADD PLAYLIST",
  scanToBuyBig: "SCAN TO BUY LICENCE",
  orVisit: "or visit",
  andenterdeviceID: "and enter this device id:",
  removePlaylist: "Remove Playlist",
  wantToRemove: "Do you want to remove ?",
  cancelBig: "CANCEL",
  //Home
  bigLiveTv: "LIVE TV",
  bigMovies: "MOVIES",
  bigSeries: "SERIES",
  //Channel-Menu
  noChannels: "This playlist has no channel data",
  searchChannels: "Search Channel",
  tapToPlay: "Tap to play the video stream.",
  categoryEmpty: "This category is empty",
  //Movies
  noMovies: "This playlist has no Movies data",
  searchMovies: "Search Movies",
  //series
  noSeries: "This playlist has no series data",
  searchSeries: "Search Series",
  //entity
  play: "PLAY",
  recommendations: "Recommendations",
  //User Info
  userInfo: "User Info",
  aboutApp: "About App",
  //Userinfo
  account: "Account",
  subscriptions: "Subscriptions",
  privacyPilicy: "Privacy & Policy",
  languge: "Languge",
  english: "English",
  italian: "Italian",
  //lastones
  playInfo: "Playlist Info",
  playName: "Playlist Name:",
  host: "Host:",
  username: "Username:",
  password: "Password:",
  packName: "Package Name:",
  expireDate: "Expire Date:",
  //privacyPolicy

  one: "1. Content Disclaimer: OrbixPlay is a platform designed to enhance your digital experience. However, it is important to note that OrbixPlay does not host, provide, or distribute video content. Our service is structured to support your digital journey, but we do not bear responsibility for the availability, quality, or content of video material, as this is outside the scope of our services.",
  two: "2. No Liability for Third-Party Content: OrbixPlay LLC is not liable for any third-party content you may access through our application. Your engagement with any external content, including its legality, reliability, or appropriateness, is solely at your own risk.",
  three:
    "3. Jurisdiction and Compliance: OrbixPlay LLC operates under the jurisdiction of the United States. Users are responsible for ensuring their use of the application is in compliance with all local, state, and federal laws.",
  four: "4. Pricing Policy: Prices for OrbixPlay’s services are subject to change at any time without prior notice. By accepting this agreement, you acknowledge and agree that pricing may vary and that any such changes will not affect your current service until the renewal of your subscription.",
  five: `5. No Warranty: The OrbixPlay application and its services are provided "as is" without any express or implied warranties, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.`,
  six: "6. Acceptance of Terms: By clicking Agree, you agree to be bound by the terms of this disclaimer and acknowledge that any violation may result in termination of your access to OrbixPlay services.",
  orbixUserAgreement: "ORBIXPLAY USER AGREEMENT AND DISCLAIMER",
  beforeyouProcc: "Before you proceed, please read and accept the following terms and conditions:",

  //additional
  exitApp: "Exit OrbixPlay",
  wantToExit: "Are you sure you want to exit OrbixPlay?",
  Resume: "Resume",
};

export default dict;
