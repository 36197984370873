const dict = {
  chooseLang: "Elige idioma",

  // Licencia
  instructions: "Instrucciones",
  step1: "Paso 1: Escanea el código QR",
  locateqr: "Ubica el código QR a tu derecha.",
  useSmartphone:
    "Usa la cámara de tu smartphone o una aplicación de escaneo de códigos QR para escanearlo. Esto te llevará al siguiente paso.",
  step2: "Paso 2: Ingresa la información de la lista de reproducción",
  afterScan:
    "Después de escanear el código QR, se te pedirá que ingreses la información de tu lista de reproducción.",
  makeSure: "Asegúrate de completar todos los campos requeridos.",
  step3: "Paso 3: Regresa a la aplicación",
  onceYou:
    "Una vez que hayas ingresado los detalles de tu lista de reproducción, puedes regresar a la aplicación y continuar tu experiencia.",
  refresh: "ACTUALIZAR",
  playlist: "Lista de reproducción",
  bigPlaylist: "LISTAS DE REPRODUCCIÓN",
  noLicence: "No tienes una licencia.",
  scanLicence: "Escanea para comprar una licencia.",
  noPlaylist: "No tienes ninguna lista de reproducción",
  privacyBig: "MODO PRIVADO",
  scanToAddBig: "ESCANEAR PARA AÑADIR LISTA",
  scanToBuyBig: "ESCANEAR PARA COMPRAR LICENCIA",
  orVisit: "o visita",
  andenterdeviceID: "e ingresa este ID de dispositivo:",
  removePlaylist: "Eliminar lista de reproducción",
  wantToRemove: "¿Quieres eliminar?",
  cancelBig: "CANCELAR",

  // Inicio
  bigLiveTv: "TV EN VIVO",
  bigMovies: "PELÍCULAS",
  bigSeries: "SERIES",

  // Menú de canales
  noChannels: "Esta lista de reproducción no tiene datos de canales",
  searchChannels: "Buscar canal",
  tapToPlay: "Toca para reproducir el video.",
  categoryEmpty: "Esta categoría está vacía",

  // Películas
  noMovies: "Esta lista de reproducción no tiene datos de películas",
  searchMovies: "Buscar películas",

  // Series
  noSeries: "Esta lista de reproducción no tiene datos de series",
  searchSeries: "Buscar series",

  // Entidad
  play: "REPRODUCIR",
  recommendations: "Recomendaciones",

  // Información del usuario
  userInfo: "Información del usuario",
  aboutApp: "Acerca de la aplicación",

  // Cuenta de usuario
  account: "Cuenta",
  subscriptions: "Suscripciones",
  privacyPilicy: "Privacidad y política",
  languge: "Idioma",
  english: "Inglés",
  italian: "Italiano",

  // Últimos detalles
  playInfo: "Información de la lista",
  playName: "Nombre de la lista:",
  host: "Servidor:",
  username: "Usuario:",
  password: "Contraseña:",
  packName: "Nombre del paquete:",
  expireDate: "Fecha de expiración:",

  // Política de privacidad
  one: "1. Descargo de responsabilidad sobre el contenido: OrbixPlay es una plataforma diseñada para mejorar tu experiencia digital. Sin embargo, es importante señalar que OrbixPlay no aloja, proporciona ni distribuye contenido de video. Nuestro servicio está diseñado para respaldar tu experiencia digital, pero no somos responsables de la disponibilidad, calidad o contenido del material de video, ya que esto está fuera de nuestro alcance.",
  two: "2. No nos hacemos responsables del contenido de terceros: OrbixPlay LLC no es responsable del contenido de terceros al que accedas a través de nuestra aplicación. Tu interacción con cualquier contenido externo, incluida su legalidad, fiabilidad o idoneidad, es bajo tu propio riesgo.",
  three:
    "3. Jurisdicción y cumplimiento: OrbixPlay LLC opera bajo la jurisdicción de los Estados Unidos. Los usuarios son responsables de asegurarse de que el uso de la aplicación cumpla con todas las leyes locales, estatales y federales.",
  four: "4. Política de precios: Los precios de los servicios de OrbixPlay están sujetos a cambios en cualquier momento sin previo aviso. Al aceptar este acuerdo, reconoces y aceptas que los precios pueden variar y que dichos cambios no afectarán tu servicio actual hasta la renovación de tu suscripción.",
  five: `5. Sin garantía: La aplicación OrbixPlay y sus servicios se proporcionan "tal cual", sin ninguna garantía expresa o implícita, incluidas, entre otras, las garantías implícitas de comerciabilidad, idoneidad para un propósito particular y no infracción.`,
  six: "6. Aceptación de términos: Al hacer clic en Aceptar, aceptas cumplir con los términos de este descargo de responsabilidad y reconoces que cualquier violación puede resultar en la terminación de tu acceso a los servicios de OrbixPlay.",
  orbixUserAgreement: "ACUERDO DE USUARIO Y DESCARGO DE RESPONSABILIDAD DE ORBIXPLAY",
  beforeyouProcc: "Antes de continuar, por favor lee y acepta los siguientes términos y condiciones:",

  //additional
  exitApp: "Salir de OrbixPlay",
  wantToExit: "¿Estás seguro de que quieres salir de OrbixPlay?",
};

export default dict;
