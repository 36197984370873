import { setIsBuffering, setGlobalStreamType, setIsPlaying, previewToNotShow } from "../state";

let playerInstance = null;
let reconnect_count = 0;
let reconnect_timer = null;
const MAX_RETRIES = 5;
let currentUrl = "";
let context = "";
let seeking = false;
let lastKnownTime = 0;
let monitorTimer = null;
let isBuffering = false;

if (!window.reconnectInfo) {
  window.reconnectInfo = { count: reconnect_count, max: MAX_RETRIES };
}

const videoPlayer = {
  destroy() {
    try {
      stopMonitoring();
      if (playerInstance) {
        playerInstance.pause();
        playerInstance.src = "";
        playerInstance.load();
        if (playerInstance.parentNode) {
          playerInstance.parentNode.removeChild(playerInstance);
        }
        playerInstance = null;
      }
      reconnect_count = 0;
      window.reconnectInfo.count = 0;
      clearTimeout(reconnect_timer);
      setIsBuffering(false);
      setIsPlaying(false);
    } catch (e) {
      console.error("Error destroying player:", e);
    }
  },

  playVideo(streamUrl, playerContext) {
    if (currentUrl === streamUrl && playerInstance) {
      return Promise.resolve();
    }

    console.log("✅Thiret");

    currentUrl = streamUrl;
    context = playerContext;
    reconnect_count = 0;
    window.reconnectInfo.count = 0;
    clearTimeout(reconnect_timer);

    return new Promise((resolve, reject) => {
      try {
        this.destroy();

        const videoElem = document.createElement("video");
        videoElem.src = streamUrl;
        videoElem.autoplay = true;
        videoElem.controls = true;
        videoElem.style.position = "absolute";
        videoElem.style.left = context === "preview" ? "49.5%" : "0";
        videoElem.style.top = context === "preview" ? "220px" : "0";
        videoElem.style.width = context === "preview" ? "570px" : "100%";
        videoElem.style.height = context === "preview" ? "320px" : "100%";
        videoElem.style.zIndex = context === "preview" ? "1" : "-1";
        videoElem.style.transform = context === "preview" ? "translate(-50%, -50%)" : "";

        document.body.appendChild(videoElem);
        playerInstance = videoElem;

        playerInstance.addEventListener("waiting", () => setIsBuffering(true));
        playerInstance.addEventListener("playing", () => {
          setIsBuffering(false);
          setIsPlaying(true);
          setGlobalStreamType("live");
          startMonitoring();
          resolve();
        });
        playerInstance.addEventListener("error", () => {
          console.error("Player error");
          stopMonitoring();
          tryReconnectMain();
          reject("Player error");
        });
      } catch (e) {
        console.error("Player initialization error:", e);
        tryReconnectMain();
        reject(e);
      }
    });
  },

  pause() {
    if (playerInstance) playerInstance.pause();
    setIsPlaying(false);
  },

  resume() {
    if (playerInstance) playerInstance.play();
    setIsPlaying(true);
  },

  seek(position) {
    if (playerInstance) playerInstance.currentTime = position / 1000;
  },
};

function startMonitoring() {
  clearInterval(monitorTimer);
  monitorTimer = setInterval(() => {
    if (isBuffering || !playerInstance) return;

    try {
      const currentTime = playerInstance.currentTime;
      console.log("Monitoring: Current time:", currentTime);
      if (currentTime === lastKnownTime) {
        console.warn("Stream stuck! Attempting to reconnect...");
        tryReconnectMain();
      } else {
        lastKnownTime = currentTime;
      }
    } catch (e) {
      console.error("Error while monitoring stream:", e);
    }
  }, 5000);
}

function stopMonitoring() {
  clearInterval(monitorTimer);
}

function tryReconnectMain() {
  clearTimeout(reconnect_timer);
  reconnect_count++;
  window.reconnectInfo.count = reconnect_count;

  if (reconnect_count > MAX_RETRIES) {
    console.log("Maximum retry attempts reached");
    setIsBuffering(false);
    return;
  }

  console.log(`Reconnection attempt ${reconnect_count}/${MAX_RETRIES}`);
  setIsBuffering(true);

  reconnect_timer = setTimeout(() => {
    try {
      if (playerInstance) {
        playerInstance.src = currentUrl;
        playerInstance.load();
        playerInstance.play();
      }
    } catch (e) {
      console.error("Error during reconnection:", e);
      tryReconnectMain();
    }
  }, 2000);
}

export default videoPlayer;
