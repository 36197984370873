import { ElementNode, Text, View, Show, hexColor, IntrinsicTextNodeStyleProps } from "@lightningtv/solid";
import { Column, Button, Row, ButtonContainer } from "@lightningtv/solid-ui";
import { useParams } from "@solidjs/router";
import { createEffect, createResource, createSignal } from "solid-js";
import { TileRowRecomandation } from "../components";
import { playListPassword, playListUrl, playListUsername, setIsPlaying } from "../../state";
import ContentBlock from "../components/ContentBlock";
import { useNavigate } from "@solidjs/router";
import styles from "../styles";
import * as provider from "../api/providers/entity";
import { assertTruthy } from "@lightningjs/renderer/utils";
import type { Tile } from "../api/formatters/ItemFormatter";
import videoPlayer from "../video";
import { t } from "../translations/configuration";

const Entity = () => {
  const params = useParams();

  let columnRef: any, backdropRef: any, entityActions: any;
  let myButton: any;

  const navigate = useNavigate();
  const [movieName, setMovieName] = createSignal(null);
  const [isVideoPlaying, setIsVideoPlaying] = createSignal(false);
  const [valueOfStream, setValueOfStream] = createSignal(null);
  const [backgroundImageEntity, setBackGroundImage] = createSignal<any>(null);

  const [backdropAlpha, setBackdropAlpha] = createSignal(0);
  const [containerExctencion, setContainerExctencion] = createSignal<any>(null);
  const [savedDataShow, setSavedData] = createSignal(null);

  const [data]: any = createResource(() => ({ ...params }), provider.getInfo);

  const [recommendations] = createResource<any, Tile[]>(() => ({ ...params }), provider.getRecommendations);

  createEffect(() => {
    const currentData: any = data();
    const newData: any = recommendations();

    if (videoPlayer) {
      videoPlayer.destroy();
    }

    if (currentData && newData) {
      setContainerExctencion(currentData.containerExtencion());

      if (currentData.backgroundImage()) {
        setBackGroundImage(currentData?.backgroundImage()[0]);
      }

      setMovieName(currentData.heroContent.title());

      //@ts-ignore
      setValueOfStream(currentData.idOfStream());
    }
  }, [data()]);

  const columnY = 640;

  const Backdrop = {
    color: hexColor("#000000"),
    alpha: 0,
    width: 1900,
    height: 890,
    x: -160,
    y: columnY,
    borderRadius: 30,
  };

  function onRowFocus(this: ElementNode) {
    //@ts-ignore
    this.children.selected?.setFocus();
    columnRef.y = columnY;
    backdropRef.y = columnY;
    backdropRef.alpha = 0;
  }

  function onEnter(this: ElementNode) {
    const currentData: any = data();
    const newData: any = recommendations();
    if (currentData.idOfStream() && newData) {
      navigate(`/entity/movie/${currentData.idOfStream()}/${newData[0].entityInfo.category_id}`);
    }
    entityActions.setFocus();
  }

  const onEscape = () => {
    if (isVideoPlaying() === true) {
      videoPlayer
        .destroy()
        .then(() => {
          console.log("Video player destroyed and video element removed.");
        })
        .catch(error => {
          console.error("Error removing video player:", error);
        });
      setIsVideoPlaying(false);
    } else {
      setIsVideoPlaying(false);

      // // Set focus back to lightning app
      document.getElementsByTagName("canvas")[0].focus();
      // entityActions.setFocus();
      // setBackdropAlpha(0);
      setTimeout(() => navigate(-1), 100);
      setIsPlaying(true);
    }
  };

  function onEnterTrailer(context: any) {
    if (valueOfStream()) {
      const streamOfID = valueOfStream();
      const nameOfMovie = movieName();
      const extc = containerExctencion();
      navigate(`/player?str=${streamOfID}&type=movie&name=${nameOfMovie}&extc=${extc}`);
    }
  }

  function onVodResume() {
    if (valueOfStream()) {
      const streamOfID = valueOfStream();
      const nameOfMovie = movieName();
      const extc = containerExctencion();
      const currentVideoUrl = `${playListUrl()}/movie/${playListUsername()}/${playListPassword()}/${streamOfID}.${extc}`;
      const savedData = localStorage.getItem("vod_resume");

      if (savedData) {
        const { videoUrl, currentTime } = JSON.parse(savedData);

        console.log("curent tie entity", currentTime);

        if (videoUrl === currentVideoUrl) {
          navigate(
            `/player?str=${streamOfID}&type=movie&name=${nameOfMovie}&extc=${extc}&seek=${currentTime}`,
          );
        }
      }
    }
  }

  createEffect(() => {
    const streamOfID = valueOfStream();
    const nameOfMovie = movieName();
    const extc = containerExctencion();
    const currentVideoUrl = `${playListUrl()}/movie/${playListUsername()}/${playListPassword()}/${streamOfID}.${extc}`;
    const savedData = localStorage.getItem("vod_resume");

    if (savedData) {
      const { videoUrl, currentTime } = JSON.parse(savedData);

      if (videoUrl === currentVideoUrl) {
        setSavedData(true);
      }
    }
  });

  return (
    <Show when={data()}>
      <View src={`${backgroundImageEntity()}`}>
        <View
          onEscape={() => {
            onEscape();
          }}
          src={"https://i.ibb.co/NrtvBBD/hero-mask-inverted.png"}
        >
          <View color={backgroundImageEntity() ? hexColor("#00000000") : hexColor("#000000")}>
            <View
              onLeft={() => {
                return true;
              }}
              x={170}
            >
              <ContentBlock
                y={160}
                x={params.type == "series" ? -100 : 0}
                content={data().heroContent}
              ></ContentBlock>

              <Row
                ref={entityActions}
                y={450}
                scroll="none"
                height={90}
                width={640}
                gap={40}
                onDown={() => {
                  if (params.type === "movie") {
                    columnRef.setFocus();
                  } else {
                    myButton.setFocus();
                  }
                }}
              >
                {params.type === "movie" && (
                  <>
                    <Button
                      style={{ color: "#222222", focus: { color: "#128DD3" }, borderRadius: 15 }}
                      width={300}
                      autofocus
                      onEnter={() => {
                        onEnterTrailer("movies");
                      }}
                    >
                      {t("play")}
                    </Button>
                    <Show when={savedDataShow()}>
                      <Button
                        onEnter={() => {
                          onVodResume();
                        }}
                        style={{ color: "#222222", focus: { color: "#F5C518" }, borderRadius: 15 }}
                        width={300}
                      >
                        {t("Resume")}
                      </Button>
                    </Show>
                  </>
                )}
              </Row>

              <Column
                ref={columnRef}
                x={0}
                y={columnY}
                style={styles.Column}
                scroll="none"
                zIndex={5}
                onUp={() => entityActions.setFocus()}
              >
                <Show when={recommendations()}>
                  <Text skipFocus style={styles.RowTitle}>
                    {t("recommendations")}
                  </Text>
                  <TileRowRecomandation
                    onFocus={onRowFocus}
                    onEnter={onEnter}
                    items={recommendations()}
                    width={1620}
                  />
                </Show>
              </Column>

              <View ref={backdropRef} style={Backdrop} transition={{ alpha: true, y: true }} />
            </View>
            <View
              alpha={backdropAlpha()}
              color={hexColor("#000000")}
              zIndex={200}
              transition={{ alpha: true }}
            />
          </View>
        </View>
      </View>
    </Show>
  );
};

export default Entity;
