import api from "..";
import { playListPassword, playListUsername } from "../../../state";
import { convertItemsToTilesChannelCategory, chunkArray } from "../formatters/ItemFormatter";

const cache = new Map<string, Promise<any>>();
const leftoverTiles = new Map<string, any[]>();

export default function (filter: string) {
  return (pageIndex: number): Promise<any> => {
    const username = playListUsername();
    const password = playListPassword();
    //@ts-ignore
    const apiIdentifier = api.getIdentifier ? api.getIdentifier() : "default";
    const cacheKey = `${apiIdentifier}_${username}_vod_categories`;
    const tileKey = `${filter}_${apiIdentifier}_${username}_vod_categories`;

    const url = `/player_api.php?username=${username}&password=${password}&action=get_vod_categories`;

    console.log(`/player_api.php?username=${username}&password=${password}&action=get_vod_categories`);

    if (cache.has(cacheKey)) {
      return cache.get(cacheKey);
    }

    const result = api._get(url).then(channels => {
      const results = channels;
      const existingTiles = leftoverTiles.get(tileKey) || [];
      const tiles = existingTiles.concat(convertItemsToTilesChannelCategory(results));

      const chunks = chunkArray(tiles);
      if (chunks[chunks.length - 1].length < 7) {
        leftoverTiles.set(tileKey, chunks.pop() || []);
      } else {
        leftoverTiles.delete(tileKey);
      }
      return tiles;
    });

    cache.set(cacheKey, result);
    return result;
  };
}
