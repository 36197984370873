import {
  //@ts-ignore
  createEffect,
  createMemo,
  createSignal,
  onMount,
  onCleanup,
} from "solid-js";
import { View, hexColor, Text, Show } from "@lightningtv/solid";
import { Button, ButtonContainer, Column } from "@lightningtv/solid-ui";
import { LazyUp } from "@lightningtv/solid/primitives";

import { useLocation, useNavigate, useParams } from "@solidjs/router";
import moviesProvider from "../../api/providers/movies";

import { createInfiniteScroll } from "../../components/pagination";

import moviesCategory from "../../api/providers/moviesCategory";
import LoadingIndicator from "../loading/loading";
import { focusStore, setFocusStore } from "../../../state";
import { t } from "../../translations/configuration";

const Movies = () => {
  const params = useParams();
  const location = useLocation();
  const [lastElement, setLastElement] = createSignal<any>(null);
  const navigate = useNavigate();
  const [number, setNumber] = createSignal<any>(null);
  const [isNumberChanging, setIsNumberChanging] = createSignal(false);
  const [shouldWait, setShouldWait] = createSignal(false);
  const [showLoading, setShowLoading] = createSignal(true);
  const [showMessage, setShowMessage] = createSignal(false);

  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  let focusTimeout: any;
  let myButton: any;
  let viewRef: any;
  let firstRef: any;
  let search: any;

  const providerCategory = createMemo(() => {
    return createInfiniteScroll(moviesCategory(params.filter || "all"));
  });

  const provider = createMemo(() => {
    return createInfiniteScroll(moviesProvider(params.filter || "all", number()));
  });

  const handleNumberChange = (newNumber: any) => {
    if (number() === newNumber) {
      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsNumberChanging(true);
    timeoutId = setTimeout(() => {
      setNumber(newNumber);
      setIsNumberChanging(false);
      timeoutId = null;
    }, 500);
  };

  function Thumbnail(props: any) {
    const newData = props;
    return (
      <Button
        ref={myButton}
        style={{
          borderRadius: 15,
          border: { width: 0, color: hexColor("#45617a") },
          color: newData.index === lastElement() ? hexColor("#128DD3") : hexColor("#222222"),
          focus: { color: hexColor("#128DD3"), textColor: hexColor("#ffffff") },
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
          height: 70,
          width: 400,
          justifyContent: "flexStart",
          alignItems: "flexStart",
        }}
        onFocus={() => {
          handleNumberChange(newData.categoryID);
          setFocusStore("moviesIndex", newData.index);
          setFocusStore("moviesID", newData.categoryID);
        }}
        onUp={() => {
          if (shouldWait()) {
            return true;
          } else {
            //@ts-ignore
            setFocusStore("moviesDetail", 0);
          }
        }}
        onDown={() => {
          if (shouldWait()) {
            return true;
          } else {
            //@ts-ignore
            setFocusStore("moviesDetail", 0);
          }
        }}
        onRight={() => {
          if (shouldWait()) {
            return true;
          }
        }}
        onLeft={() => {
          if (shouldWait()) {
            return true;
          }
        }}
      >
        {newData.category_name}
      </Button>
    );
  }

  function ItemMovie(props: any) {
    const newData = props;
    const rating = newData?.data?.rating_5based ?? 0;

    const handleEnter = () => {
      navigate(`/entity/movie/${newData.data.stream_id}/${newData.data.category_id}`);
      setFocusStore("moviesDetail", newData.index);
    };

    return (
      <View
        ref={myButton}
        style={{
          borderRadius: 15,
          border: { width: 0, color: hexColor("#45617a") },
          color: hexColor("#222222"),
          focus: { color: hexColor("#128DD3"), textColor: hexColor("#ffffff") },
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
          height: 225,
          width: 1300,
          justifyContent: "flexStart",
          alignItems: "flexStart",
        }}
        onFocus={() => {
          // localStorage.removeItem("moviesDetail");
        }}
        onEnter={handleEnter}
      >
        <View src={newData.src} x={7} y={7} style={{ width: 130, height: 210, borderRadius: 10 }}></View>
        <View x={20}>
          <Text x={160} y={20} style={{ fontSize: 36 }}>
            {newData.data.name}
          </Text>

          <View x={10} y={70}>
            {Array.from({ length: 5 }).map((_, index) => {
              const fullStars = Math.floor(rating);
              const hasHalfStar = rating % 1 >= 0.5;

              const starXPosition = 150 + index * 40;
              let starSrc = "https://i.ibb.co/1KBsPWh/star-2.png"; // Empty star

              if (index < fullStars) {
                starSrc = "https://i.ibb.co/4gK6MnK/star-1.png"; // Full star
              } else if (index === fullStars && hasHalfStar) {
                starSrc = "https://i.ibb.co/9V7LVwc/rating.png"; // Half star
              }

              return <View key={index} x={starXPosition} width={30} height={30} src={starSrc} />;
            })}
          </View>
          <Text x={160} y={120} style={{ fontSize: 32 }}>
            {newData.data.rating_5based}/5
          </Text>
        </View>
      </View>
    );
  }

  createEffect(() => {
    localStorage.setItem("@emptyCategory", providerCategory().pages()?.length > 0 ? "false" : "true");

    const timeout = setTimeout(() => {
      setShowMessage(providerCategory().pages().length > 0 ? false : true);
      setShowLoading(false);
    }, 15000);

    onCleanup(() => clearTimeout(timeout));
  });

  createEffect(() => {
    const enterID = focusStore.moviesID;

    if (enterID !== null) {
      handleNumberChange(enterID);
    }
  });

  onMount(() => {
    if (focusStore.moviesDetail === null) {
      return;
    }

    // if (focusStore.moviesIndex === null) {
    //   setFocusStore("moviesIndex", 0);
    // }

    setShouldWait(true);

    if (providerCategory() && location.pathname.startsWith("/movies")) {
      const timeout = setTimeout(() => {
        viewRef?.setFocus();
        setShouldWait(false);
      }, 1500);

      onCleanup(() => clearTimeout(timeout));
    } else {
      setShouldWait(false);
    }
  });

  const handleEscape = () => {
    if (!shouldWait()) {
      setFocusStore("moviesIndex", null);
      setFocusStore("moviesDetail", null);
      setFocusStore("moviesID", null);
      clearTimeout(focusTimeout);
      focusTimeout = null;
      navigate("/home");
    }
  };

  return (
    <View color={hexColor("#000000")} onEscape={handleEscape}>
      <View y={30}>
        <Show
          when={providerCategory().pages() && providerCategory().pages()?.length > 0}
          fallback={
            <View x={750} y={500}>
              <Text fontSize={30} color={hexColor("#7d7e82")}>
                {t("noMovies")}
              </Text>
            </View>
          }
        >
          <View
            x={150}
            y={10}
            onEnter={() => {
              navigate("/search");
            }}
            // @ts-ignore
            style={{
              borderRadius: 30,
              width: 400,
              height: 70,
              scale: 1,
              zIndex: 2,
              color: hexColor("#222222"),
              transition: { scale: { duration: 250, easing: "ease-in-out" } },
              border: { width: 0, color: 0x00000000 },
              focus: { scale: 1.1, color: hexColor("#128DD3") },
            }}
            ref={search}
            onDown={() => {
              if (location.pathname.startsWith("/movies")) {
                firstRef.setFocus();
              }
            }}
          >
            <View x={25} y={20} width={30} height={30} src="https://i.ibb.co/rHLz71b/search-50.png" />

            <Text x={80} y={25} width={60} style={{ color: hexColor("#7d7e82"), fontSize: 22, width: 10 }}>
              {t("searchMovies")}
            </Text>
          </View>
          <View clipping y={80}>
            {/* @ts-ignore */}
            <LazyUp
              onRight={() => {
                if (provider().pages() && provider().pages()?.length > 0 && !isNumberChanging()) {
                  viewRef.setFocus();
                } else {
                  console.log("akoma jo");
                }
              }}
              onUp={() => search.setFocus()}
              x={150}
              onSelectedChanged={setLastElement}
              y={10}
              gap={10}
              autofocus={true}
              ref={firstRef}
              component={Column}
              scroll="always"
              upCount={15}
              selected={focusStore.moviesIndex === null ? 0 : focusStore.moviesIndex}
              each={providerCategory().pages()}
            >
              {(item: any, i: number) => <Thumbnail {...item} index={i} />}
            </LazyUp>
            {/* <LazyColumn
              ref={firstRef}
              upCount={15}
              selected={20}
              each={providerCategory().pages()}
            >
              {(item: any, i: number) => <Button>kevi</Button>}
            </LazyColumn> */}

            <View x={570} y={10} clipping>
              <Show
                when={provider().pages() && provider().pages()?.length > 0 && !isNumberChanging()}
                fallback={
                  <View x={500} y={330} width={100} height={100}>
                    {showLoading() && <LoadingIndicator isCategoriesIndicator={true} />}
                    {showMessage() && (
                      <Text y={50} style={{ color: hexColor("#7d7e82"), fontSize: 22, width: 10 }}>
                        {t("categoryEmpty")}
                      </Text>
                    )}
                  </View>
                }
              >
                {/* @ts-ignore */}
                <LazyUp
                  x={20}
                  onLeft={() => firstRef.setFocus()}
                  component={Column}
                  ref={viewRef}
                  scroll="always"
                  // direction="column"
                  upCount={6}
                  each={provider().pages()}
                  selected={
                    focusStore.moviesDetail === null
                      ? 0
                      : (() => {
                          const index = focusStore.moviesDetail;
                          if (index !== null) {
                            setTimeout(() => {}, 300);
                            return index;
                          }
                        })()
                  }
                >
                  {(item: any, i: number) => <ItemMovie {...item} index={i} />}
                </LazyUp>
              </Show>
            </View>
          </View>
        </Show>
      </View>
    </View>
  );
};

export default Movies;
