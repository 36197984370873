import { ElementNode, Text, View, Show, hexColor, IntrinsicTextNodeStyleProps } from "@lightningtv/solid";
import { Button, ButtonContainer, Column } from "@lightningtv/solid-ui";
import { useParams } from "@solidjs/router";
import {
  createEffect,
  createResource,
  createSignal,
  createSelector,
  //@ts-ignore
  For,
  createMemo,
  onMount,
} from "solid-js";
import { setIsPlaying } from "../../state";
import { useNavigate } from "@solidjs/router";
import styles from "../styles";
import * as provider from "../api/providers/entity";
import { assertTruthy } from "@lightningjs/renderer/utils";
import type { Tile } from "../api/formatters/ItemFormatter";
import videoPlayer from "../video";
import { createInfiniteScroll } from "../components/pagination";
import episodesProvider from "../api/providers/episodesProvider";
import ContentBlockSeries from "../components/ContentBlockSeries";
import LoadingIndicator from "./loading/loading";

const RowStyles: any = {
  display: "flex",
  justifyContent: "flexStart",
  width: 300,
  height: 300,
  color: hexColor("00000000"),
  gap: 26,
  y: -110,
} satisfies IntrinsicTextNodeStyleProps;

const columnY = 640;

const Backdrop = {
  color: hexColor("#000000"),
  alpha: 0,
  width: 1900,
  height: 890,
  x: -160,
  y: columnY,
  borderRadius: 30,
};

const SerieDetails = () => {
  const params = useParams();

  let backdropRef: any, seriesRow: any;
  let myButton: any;
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const navigate = useNavigate();
  const [isVideoPlaying, setIsVideoPlaying] = createSignal(false);
  const [isNumberChanging, setIsNumberChanging] = createSignal(false);
  const [number, setNumber] = createSignal(null);
  const [index, setIndex] = createSignal(0);

  const [data] = createResource(() => ({ ...params }), provider.getInfo);

  const [seasons] = createResource<any, Tile[]>(() => ({ ...params }), provider.getSeriesSeasons);

  const providerOfSeries = createMemo(() => {
    //@ts-ignore
    return createInfiniteScroll(episodesProvider(params.filter || "all", number(), index()));
  });

  const isFirst = createSelector(() => {
    return 0;
  });

  const onEscape = () => {
    if (isVideoPlaying() === true) {
      videoPlayer
        .destroy()
        .then(() => {
          console.log("Video player destroyed and video element removed.");
        })
        .catch(error => {
          console.error("Error removing video player:", error);
        });
      setIsVideoPlaying(false);
    } else {
      setIsVideoPlaying(false);

      document.getElementsByTagName("canvas")[0].focus();
      setTimeout(() => navigate("/series"), 100);
      setIsPlaying(true);
    }
  };

  const onEnterSeries = (idSeries: any, title: any, container_extension: any) => {
    navigate(
      `/player?str=${idSeries}&type=series&name=${encodeURIComponent(title)}&extc=${container_extension}`,
    );
  };

  const handleNumberChange = (newNumber: any) => {
    if (index() === newNumber()) {
      return;
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsNumberChanging(true);
    timeoutId = setTimeout(() => {
      setIndex(newNumber());
      setIsNumberChanging(false);
      timeoutId = null;
    }, 500);
  };

  createEffect(() => {
    if (seasons()) {
      //@ts-ignore
      setNumber(params.id);
    }
    if (videoPlayer) {
      videoPlayer.destroy();
    }
  });

  function DemoTile(props) {
    console.log("prosp", props);

    return (
      <ButtonContainer
        onEnter={() => {
          onEnterSeries(props.id, props.title, props.container_extension);
        }}
        //@ts-ignore
        states={"active"}
        //@ts-ignore
        style={{
          borderRadius: 7,
          width: 530,
          height: 85,
          scale: 1,
          zIndex: 2,
          color: hexColor("#222222"),
          transition: { scale: { duration: 250, easing: "ease-in-out" } },
          border: { width: 0, color: 0x00000000 },
          focus: { scale: 1.1, color: hexColor("#128DD3") },
        }}
      >
        <View>
          <View src={props.info.movie_image} style={{ width: 80, borderRadius: 10 }} />

          <Text x={90} y={30} style={{ fontSize: 28 }}>
            {props.title.length > 30 ? props.title.slice(0, 30) + "..." : props.title}
          </Text>
        </View>
      </ButtonContainer>
    );
  }

  return (
    <>
      <View
        onEscape={() => {
          onEscape();
        }}
        color={hexColor("#000000")}
      >
        <View
          onLeft={() => {
            return true;
          }}
          x={170}
        >
          <ContentBlockSeries
            y={80}
            x={params.type == "series" ? -100 : 0}
            content={data().heroContent}
          ></ContentBlockSeries>

          <Text x={680} y={50} style={{ fontSize: 35, fontWeight: "bold" }}>
            Seasons
          </Text>
          <Text x={1160} y={50} style={{ fontSize: 35, fontWeight: "bold" }}>
            Episodes
          </Text>

          <Show
            when={seasons()}
            fallback={
              <View x={1000} y={450}>
                <LoadingIndicator isCategoriesIndicator={true} />
              </View>
            }
          >
            <View x={680} y={100} clipping>
              <Column
                transition={{ x: { duration: 350 } }}
                ref={myButton}
                onRight={() => {
                  seriesRow.setFocus();
                }}
                gap={20}
                style={RowStyles}
                y={50}
                scroll="always"
              >
                <For each={seasons()}>
                  {(categorie, i) => {
                    return (
                      <ButtonContainer
                        transition={{ x: { duration: 350 } }}
                        width={410}
                        height={70}
                        borderRadius={10}
                        //@ts-ignore``
                        style={{ color: hexColor("#222222"), focus: { color: hexColor("#128DD3") } }}
                        autofocus={isFirst(i())}
                        index={i()}
                        //@ts-ignore
                        states={"active"}
                        onEnter={() => {
                          handleNumberChange(i);
                        }}
                      >
                        <Text fontSize={24} y={23} x={23}>
                          {categorie}
                        </Text>
                      </ButtonContainer>
                    );
                  }}
                </For>
              </Column>

              <Show
                when={providerOfSeries().pages() && !isNumberChanging()}
                fallback={
                  <View x={480} y={80}>
                    <Text style={{ fontSize: 25 }}>No episodes available</Text>
                  </View>
                }
              >
                <Column
                  ref={seriesRow}
                  onLeft={() => {
                    myButton.setFocus();
                  }}
                  plinko
                  announce="Series"
                  style={styles.Column}
                  x={480}
                  gap={15}
                  selected={0}
                  y={48}
                >
                  <For each={providerOfSeries().pages()}>
                    {(demo, i) => <DemoTile index={i()} {...demo} />}
                  </For>
                </Column>
              </Show>
            </View>
          </Show>
          <View ref={backdropRef} style={Backdrop} transition={{ alpha: true, y: true }} />
        </View>
        <View alpha={0} color={hexColor("#000000")} zIndex={200} transition={{ alpha: true }} />
      </View>
    </>
  );
};

export default SerieDetails;
