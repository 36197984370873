//@ts-ignore
import { createEffect, createSignal, onCleanup, For, onMount, Show } from "solid-js";
import { View, Text, hexColor } from "@lightningtv/solid";
import { useNavigate, useParams } from "@solidjs/router";
import { ButtonContainer, Row } from "@lightningtv/solid-ui";
// import {  } from "@lightningtv/solid/primitives";

import {
  isVpnMode,
  playListName,
  playListPassword,
  playListUrl,
  playListUsername,
  setIsPlaying,
  setIsVpnMode,
} from "../../state";

import { fetchData } from "../api/workers/fetchWorkerManager";
import { t } from "../translations/configuration";

const homeStyle: any = {
  borderRadius: 50,
  width: 550,
  height: 550,
  scale: 1,
  zIndex: 2,
  color: hexColor("#222222"),
  transition: { scale: { duration: 250, easing: "ease-in-out" } },
  border: { width: 0, color: 0x00000000 },
  focus: { scale: 1.03, color: hexColor("#128DD3") },
  alignItems: "center",
  justifyContent: "center",
};

const button: any = {
  borderRadius: 20,
  scale: 1,
  zIndex: 2,
  color: hexColor("#222222"),
  transition: { scale: { duration: 250, easing: "ease-in-out" } },
  border: { width: 0, color: 0x00000000 },
  focus: { scale: 1.03, color: hexColor("#128DD3") },
  width: 50,
  height: 92,
};

export function ThumbnailHome(props) {
  const textLength = props.data.name.length;
  const textX = 260 - textLength * 11; // Adjust position based on text length

  return (
    <View style={homeStyle} onEnter={props.onEnter}>
      <View src={props.src} width={300} height={300} mountX={-0.4} mountY={-0.2} />

      <Text
        x={textX}
        y={400}
        style={{
          fontSize: 48,
          fontWeight: "bold",
          color: hexColor("#b6c6d3"),
          alignSelf: "center",
        }}
      >
        {props.data.name}
      </Text>
    </View>
  );
}

export function RowHome(props) {
  return (
    <Row autofocus {...props} scroll="none" gap={40}>
      <For each={props.items}>{item => <ThumbnailHome {...item} />}</For>
    </Row>
  );
}

const HomePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [time, setTime] = createSignal(new Date());
  const updateTime = () => setTime(new Date());
  const interval = setInterval(updateTime, 1000);
  onCleanup(() => clearInterval(interval));
  // const [screenWidth] = createSignal(window.innerWidth);
  // const [screenHeight] = createSignal(window.innerHeight);

  const onEnterCustom = () => {
    navigate("/channel-menu");
  };
  let col1;
  let col2;
  createEffect(() => {
    setIsPlaying(false);
  });
  const liveTV = () => {
    navigate("/channel-menu");
    setIsPlaying(true);
  };
  const movies = () => {
    navigate("/movies");
    setIsPlaying(true);
  };
  const series = () => {
    navigate("/series");
    setIsPlaying(true);
  };
  const playlists = () => {
    navigate("/");
  };
  const menuItems = [
    { src: "https://i.ibb.co/261M4fV/tvicon.png", data: { name: `${t("bigLiveTv")}` }, onEnter: liveTV },
    {
      src: "https://i.ibb.co/gdydbkV/moviesicon.png",
      data: { name: `${t("bigMovies")}` },
      onEnter: movies,
    },
    {
      src: "https://i.ibb.co/2FWYD3j/seriesicon.png",
      data: { name: `${t("bigSeries")}` },
      onEnter: series,
    },
  ];
  const bottomItems = [
    // { src: "./assets/privacy_mode.png", data: { name: "Privacy Mode" } },
    { src: "./assets/settings.png", data: { name: "S" } },
    // { src: "./assets/playlists.png", data: { name: "Playlists" } }
  ];

  const togglePrivacyMode = () => {
    setIsVpnMode(prev => {
      const newMode = !prev;
      localStorage.setItem("vpn", newMode ? "on" : "off");
      return newMode;
    });
  };

  createEffect(() => {
    const fetchDataFromWorker = async () => {
      const username = playListUsername();
      const password = playListPassword();
      const name = playListUrl();
      const url = `${name}/player_api.php?username=${username}&password=${password}&action=get_vod_streams`;

      try {
        // const newApi = await api._get(url);
        const result = await fetchData(url);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataFromWorker();
  });

  return (
    <View
      color={hexColor("#000000")}
      onEscape={() => {
        navigate("/");
        // fetchDataFromWorker()
      }}
    >
      <View flexItem={false} x={30} y={15} zIndex={105}>
        <View y={30} x={30} src="https://i.ibb.co/8P73RcM/orbixplay-logo-2.png" width={280} height={85} />
      </View>
      <View y={70} x={1430}>
        <Text style={{ fontSize: 28 }}>
          {time().toDateString()} {time().toLocaleTimeString()}
        </Text>
      </View>
      <View>
        <View width={70} x={90} y={200} onDown={() => col2.setFocus()}>
          <RowHome items={menuItems} ref={col1} />
        </View>
      </View>
      <View y={800} onUp={() => col1.setFocus()}>
        <Row
          ref={col2}
          scroll="none"
          style={{
            display: "flex",
            justifyContent: "spaceBetween",
            flexDirection: "row",
          }}
        >
          <View
            onEnter={() => {
              togglePrivacyMode();
            }}
            style={button}
            mountX={-0.22}
            y={100}
            height={90}
            borderRadius={20}
            width={400}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text fontSize={30} y={35} x={80} fontWeight={"bold"}>
                {t("privacyBig")}
              </Text>
              <Show
                when={isVpnMode()}
                fallback={
                  <View
                    x={20}
                    y={32}
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: 40,
                      color: isVpnMode() ? hexColor("#00ff00") : hexColor("#777"),
                      marginRight: 10,
                    }}
                  />
                }
              >
                <View
                  x={20}
                  y={32}
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 40,
                    color: isVpnMode() ? hexColor("#00ff00") : hexColor("#777"),
                    marginRight: 10,
                  }}
                />
              </Show>
            </View>
          </View>
          <ButtonContainer
            style={button}
            y={100}
            width={170}
            mountX={-2.0}
            borderRadius={20}
            onEnter={() => {
              navigate("/settings");
            }}
          >
            <View
              x={65}
              y={25}
              src="https://i.postimg.cc/hvTWsvm4/settings.png"
              width={40}
              height={40}
            ></View>
          </ButtonContainer>

          <View
            onEnter={() => playlists()}
            style={button}
            mountX={0.3}
            y={100}
            height={90}
            borderRadius={20}
            width={400}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text fontSize={30} y={35} x={80} fontWeight={"bold"}>
                {t("bigPlaylist")}
              </Text>
              <View x={25} y={25} width={40} height={40} src="https://i.ibb.co/DYGFmHF/playlist.png" />
            </View>
          </View>

          {/* <Button
              style={button}
              y={100}
              width={400}
              mountX={0.26}
              borderRadius={20}
              onEnter={() => playlists()}
            >
              Playlists
            </Button> */}
        </Row>
      </View>
    </View>
  );
};
export default HomePage;
