const dict = {
  chooseLang: "Scegli la lingua",

  // Licenza
  instructions: "Istruzioni",
  step1: "Passo 1: Scansiona il codice QR",
  locateqr: "Individua il codice QR alla tua destra.",
  useSmartphone:
    "Usa la fotocamera del tuo smartphone o un'app per scannerizzare codici QR per scansionarlo. Questo ti porterà al passaggio successivo.",
  step2: "Passo 2: Inserisci le informazioni sulla playlist",
  afterScan:
    "Dopo aver scansionato il codice QR, ti verrà chiesto di inserire le informazioni della tua playlist.",
  makeSure: "Assicurati di compilare tutti i campi richiesti.",
  step3: "Passo 3: Torna all'app",
  onceYou:
    "Una volta inseriti i dettagli della tua playlist, puoi tornare all'app e continuare la tua esperienza.",
  refresh: "AGGIORNA",
  playlist: "Playlist",
  bigPlaylist: "PLAYLIST",
  noLicence: "Non hai una licenza.",
  scanLicence: "Scansiona per acquistare una licenza.",
  noPlaylist: "Non hai alcuna playlist",
  privacyBig: "MODALITÀ PRIVACY",
  scanToAddBig: "SCANSIONA PER AGGIUNGERE PLAYLIST",
  scanToBuyBig: "SCANSIONA PER ACQUISTARE LICENZA",
  orVisit: "oppure visita",
  andenterdeviceID: "e inserisci questo ID dispositivo:",
  removePlaylist: "Rimuovi Playlist",
  wantToRemove: "Vuoi rimuoverla?",
  cancelBig: "ANNULLA",

  // Home
  bigLiveTv: "TV IN DIRETTA",
  bigMovies: "FILM",
  bigSeries: "SERIE",

  // Menu Canali
  noChannels: "Questa playlist non contiene dati sui canali",
  searchChannels: "Cerca Canale",
  tapToPlay: "Tocca per riprodurre il video in streaming.",
  categoryEmpty: "Questa categoria è vuota",

  // Film
  noMovies: "Questa playlist non contiene dati sui film",
  searchMovies: "Cerca Film",

  // Serie
  noSeries: "Questa playlist non contiene dati sulle serie",
  searchSeries: "Cerca Serie",

  // Entità
  play: "RIPRODUCI",
  recommendations: "Raccomandazioni",

  // Informazioni Utente
  userInfo: "Informazioni Utente",
  aboutApp: "Informazioni sull'App",

  // Informazioni Account
  account: "Account",
  subscriptions: "Abbonamenti",
  privacyPilicy: "Privacy e Politica",
  languge: "Lingua",
  english: "Inglese",
  italian: "Italiano",

  // Ultimi dettagli
  playInfo: "Informazioni sulla Playlist",
  playName: "Nome Playlist:",
  host: "Host:",
  username: "Nome utente:",
  password: "Password:",
  packName: "Nome Pacchetto:",
  expireDate: "Data di Scadenza:",

  // Politica sulla Privacy
  one: "1. Dichiarazione di responsabilità sui contenuti: OrbixPlay è una piattaforma progettata per migliorare la tua esperienza digitale. Tuttavia, è importante notare che OrbixPlay non ospita, fornisce o distribuisce contenuti video. Il nostro servizio è strutturato per supportare il tuo percorso digitale, ma non siamo responsabili della disponibilità, qualità o contenuto del materiale video, poiché ciò esula dal nostro servizio.",
  two: "2. Nessuna responsabilità per contenuti di terze parti: OrbixPlay LLC non è responsabile per eventuali contenuti di terze parti a cui puoi accedere tramite la nostra applicazione. Il tuo coinvolgimento con qualsiasi contenuto esterno, inclusa la sua legalità, affidabilità o idoneità, è esclusivamente a tuo rischio.",
  three:
    "3. Giurisdizione e conformità: OrbixPlay LLC opera sotto la giurisdizione degli Stati Uniti. Gli utenti sono responsabili di garantire che l'uso dell'applicazione sia conforme a tutte le leggi locali, statali e federali.",
  four: "4. Politica sui prezzi: I prezzi dei servizi di OrbixPlay sono soggetti a modifiche in qualsiasi momento senza preavviso. Accettando questo accordo, riconosci e accetti che i prezzi possono variare e che tali modifiche non influenzeranno il tuo servizio attuale fino al rinnovo dell'abbonamento.",
  five: `5. Nessuna garanzia: L'applicazione OrbixPlay e i suoi servizi sono forniti "così come sono" senza alcuna garanzia esplicita o implicita, incluse, ma non limitate a, garanzie implicite di commerciabilità, idoneità per uno scopo particolare e non violazione.`,
  six: "6. Accettazione dei termini: Cliccando su Accetto, accetti di essere vincolato dai termini di questa dichiarazione di non responsabilità e riconosci che qualsiasi violazione potrebbe comportare la cessazione del tuo accesso ai servizi OrbixPlay.",
  orbixUserAgreement: "ACCORDO E DICHIARAZIONE DI RESPONSABILITÀ UTENTE ORBIXPLAY",
  beforeyouProcc: "Prima di procedere, leggi e accetta i seguenti termini e condizioni:",

  //additional
  exitApp: "Esci da OrbixPlay",
  wantToExit: "Sei sicuro di voler uscire da OrbixPlay?",
};

export default dict;
