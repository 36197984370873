import { createSignal, onMount, Show } from "solid-js";
import { SolidQR } from "solid-qr";
import { View, Text, hexColor } from "@lightningtv/solid";

const QrCode = props => {
  const URL = `https://orbixplay.com/mylist/?client_reference_id=${props.api_token}`;

  return (
    <View>
      <SolidQR text={URL} />
    </View>
  );
};

export default QrCode;
