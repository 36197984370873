//@ts-ignore
import { createSignal } from "solid-js";
import { createStore } from "solid-js/store";
export const [globalBackground, setGlobalBackground] = createSignal<string>("");
export const [globalStream, setGlobalStream] = createSignal<string>("");
export const [globalStreamType, setGlobalStreamType] = createSignal<string>("");
export const [isPlaying, setIsPlaying] = createSignal<boolean>(true);
export const [isLoading, setIsLoading] = createSignal<boolean>(false);
export const [playListUrl, setPlayListUrl] = createSignal<string>("");
export const [playListUsername, setPlayListUsername] = createSignal<string>("");
export const [playListPassword, setPlayListPassword] = createSignal<string>("");
export const [isVpnMode, setIsVpnMode] = createSignal<boolean>(false);
export const [isBuffering, setIsBuffering] = createSignal<boolean>(false);
export const [playListName, setPlayListName] = createSignal<string>("");
export const [hidePlaylistInfo, setHidePlaylistInfo] = createSignal<string>("");
export const [channelMenu, setChannelMenu] = createSignal(false);
export const [liveTvStreamID, setLiveTvStreamID] = createSignal<any>(null);
export const [lastStream, setLastStream] = createSignal<any>(null);
export const [showReconnecting, setShowReconnecting] = createSignal(false);
export const [deviceYear, setDeviceYear] = createSignal<any>(null);

export const [previewToNotShow, setPreviewToNotShow] = createSignal(true);

export const [focusStore, setFocusStore] = createStore({
  //channels
  focusedIndexChannels: null,
  thumbnailIndexChannels: null,
  enterChannels: null,
  colonIndexChannels: null,
  //movies
  moviesIndex: null,
  moviesDetail: null,
  moviesID: null,
  //series
  seriesIndex: null,
  seriesDetail: null,
  seriesID: null,
});
