import {
  createMemo,
  createSignal,
  Show,
  createSelector,
  For,
  createEffect,
  on,
  onMount,
  Index,
  splitProps,
  onCleanup,
} from "solid-js";
import {
  IntrinsicTextNodeStyleProps,
  View,
  Text,
  hexColor,
  ElementNode,
  IntrinsicNodeProps,
} from "@lightningtv/solid";
import { Button, ButtonContainer, Column, Row } from "@lightningtv/solid-ui";
import { useLocation, useNavigate, useParams } from "@solidjs/router";
import { TileRowProps } from "../../components";
import styles from "../../styles";
import channelsProvider from "../../api/providers/channels";
import { createInfiniteScroll } from "../../components/pagination";
import channelsCategories from "../../api/providers/channelsCategories";
import SelectedChannelEpg from "./channelMenuComponents/SelectedChannelEpg";
import { LazyUp } from "@lightningtv/solid/primitives";
import videoPlayer from "../../videoPreview";
// import videoPlayer from "../../video";
import videoPlayerPreview from "../../video";
// import { showPreviewVideo } from "../../avPlayerPreview";
import {
  channelMenu,
  focusStore,
  isBuffering,
  lastStream,
  liveTvStreamID,
  playListPassword,
  playListUrl,
  playListUsername,
  setChannelMenu,
  setFocusStore,
  setIsBuffering,
  setLastStream,
  setLiveTvStreamID,
  setPreviewToNotShow,
} from "../../../state";
import LoadingIndicator from "../loading/loading";
import { Buffer } from "buffer";
import { useDebounce } from "../../components/useDebounce";
import { t } from "../../translations/configuration";

const ChannelMenu = props => {
  let myButton: any;
  let search: any;
  let viewRef: any;
  let timeoutId: any = false;
  let preview: any;
  let channelItem: any;
  let addFavButtonRef: any;
  let debouncedHandleCategoryPress: any;
  let timeoutOfEpg: any;
  let searchIndexTimeout: any;
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [lastCategoryID, setLastCategoryID] = createSignal(null);
  const [number, setNumber] = createSignal("1");
  const [isFavChannel, setIsFavChannel] = createSignal(false);
  const [channelLogo, setChannelLogo] = createSignal("");
  const [listOfFav, setListOfFav] = createSignal<any>({});
  const [channelData, setChannelData] = createSignal<any>({});
  const [isScreenFirstOpen, setIsScreenFirstOpen] = createSignal(true);
  const [isPreviewFocused, setIsPreviewFocused] = createSignal(false);
  const [isChannelsLoading, setIsChannelsLoading] = createSignal(false);
  const [enter, setEnter] = createSignal<any>(null);
  const [lastElement, setLastElement] = createSignal<any>(null);
  const [something, setSomething] = createSignal<any>(true);
  const [shouldStart, setShouldStart] = createSignal<any>(true);
  const [isDataReady, setIsDataReady] = createSignal(false);
  const [canExecuteEscape, setCanExecuteEscape] = createSignal(false);
  const [isReadyToRight, setIsReadyToRight] = createSignal(false);

  const providerCategory = createMemo(() => {
    return createInfiniteScroll(channelsCategories(params.filter || "all"));
  });

  const provider = createMemo(() => {
    return createInfiniteScroll(channelsProvider(params.filter || "all", number()));
  });

  const handleNumberChange = (newNumber: any) => {
    if (number() === newNumber) {
      return;
    }
    setNumber(newNumber);
  };

  const handleCategoryPress = (categoryID: string) => {
    handleNumberChange(categoryID);
    setIsChannelsLoading(false);
    setTimeout(() => {
      setIsReadyToRight(false);
    }, 200);
  };

  function customEnter(stream_id: any) {
    setPreviewToNotShow(false);
    videoPlayerPreview.setFullScreen();
    navigate(`/livetv?num=${stream_id}`);
    setFocusStore("enterChannels", enter());
  }

  function TileRowChannel(props: TileRowProps) {
    const [local, others] = splitProps(props, ["items"]);

    return (
      // @ts-ignore
      <Row
        selected={
          focusStore.thumbnailIndexChannels === null
            ? 0
            : (() => {
                const index = focusStore.thumbnailIndexChannels;
                return index;
              })()
        }
        {...others}
        style={styles.RowChannel}
      >
        {/* @ts-ignore */}
        <For each={local.items}>{(item, i) => <ThumbnailChannel {...item} index={i()} />}</For>
      </Row>
    );
  }

  const addToFavorites = (channel: any) => {
    console.log("channel", channel);

    const existingChannels = localStorage.getItem("@favoriteChannels");
    let existingChannelsArray: any = existingChannels ? JSON.parse(existingChannels) : [];

    const isChannelExist = existingChannelsArray.some(
      (existingChannel: any) => existingChannel.data.stream_id === channel.data.stream_id,
    );

    if (!isChannelExist) {
      existingChannelsArray.push(channel);
    } else {
      existingChannelsArray = existingChannelsArray.filter(
        (existingChannel: any) => existingChannel.data.stream_id !== channel.data.stream_id,
      );
    }

    localStorage.setItem("@favoriteChannels", JSON.stringify(existingChannelsArray));

    console.log("keshtu futet ne fund", JSON.stringify(existingChannelsArray));

    setListOfFav(existingChannelsArray);

    setIsFavChannel(
      existingChannelsArray.some((favChannel: any) => favChannel.data.stream_id === channel.data.stream_id),
    );
  };

  const showPreviewVideo = async (streamUrl: string) => {
    if (!location.pathname.startsWith("/channel-menu") || lastStream() === streamUrl) return;

    setLastStream(streamUrl);
    videoPlayerPreview.destroy();

    try {
      await videoPlayerPreview.playVideo(streamUrl, "preview");
      videoPlayerPreview.setPreviewScreen();
      setShouldStart(true);
    } catch (error) {
      console.error("Error playing video:", error);
    }
  };

  const startDirect = async (streamUrl: string) => {
    if (!location.pathname.startsWith("/channel-menu") || lastStream() === streamUrl) return;

    setLastStream(streamUrl);

    try {
      navigate(`/livetv?num=${streamUrl}&isTrue=true`);
    } catch (error) {
      console.error("Error playing video:", error);
    }
  };

  const getStreamUrl = (stream_id: string) => {
    const baseUrl = `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/${stream_id}.ts`;
    return localStorage.getItem("vpn") === "on" && localStorage.getItem("proxy_url")
      ? `${localStorage.getItem("proxy_url")}${Buffer.from(baseUrl, "utf-8").toString("base64")}`
      : baseUrl;
  };

  function ThumbnailChannel(props: any) {
    let timeoutPreview: any;

    const isFavorited = () =>
      listOfFav().some((favChannel: any) => favChannel.data.stream_id === props.data.stream_id);

    const handleEnter = () => {
      setEnter(props.data.category_id);
      setIsFavChannel(isFavorited());
      setFocusStore("thumbnailIndexChannels", props.index);

      if (!shouldStart()) {
        setShouldStart(false);
      }

      if (lastStream() !== getStreamUrl(props.data.stream_id) && videoPlayerPreview) {
        videoPlayerPreview.destroy();
        setPreviewToNotShow(false);
        navigate(`/livetv?num=${props.data.stream_id}&isTrue=true`);
        console.log("Hyri ne e para");
        clearTimeout(timeoutPreview);

        setFocusStore("enterChannels", enter());
      } else {
        customEnter(props.data.stream_id);
      }

      // preview.setFocus();
    };

    const handleFocus = () => {
      setChannelLogo(props.data.stream_icon);
      timeoutOfEpg = setTimeout(() => {
        setChannelData(props);
        setIsDataReady(true);
      }, 700);

      setFocusStore("enterChannels", null);

      timeoutPreview = setTimeout(() => {
        if (lastStream() === getStreamUrl(props.data.stream_id)) {
          return true;
        }
        setShouldStart(false);

        videoPlayerPreview.destroy();
        showPreviewVideo(getStreamUrl(props.data.stream_id));
      }, 1550);
    };

    return (
      <View
        y={10}
        style={styles.ThumbnailChannelList}
        ref={channelItem}
        onEnter={handleEnter}
        onBlur={() => {
          clearTimeout(timeoutPreview);
        }}
        onFocus={handleFocus}
      >
        <View x={30} y={20} style={{ width: 140, height: 150 }}>
          <View
            x={60}
            y={10}
            src={props.data.stream_icon ? props.data.stream_icon : "./assets/television.png"}
            width={90}
            height={props.data.stream_icon ? 60 : 70}
            style={{ alignItems: "center" }}
          />

          <Text x={10} y={100} width={10} style={{ color: hexColor("#ffffff"), fontSize: 20, width: 10 }}>
            {props.data.num}.{" "}
            {props.data.name.length > 10 ? `${props.data.name.substring(0, 10)}...` : props.data.name}
          </Text>

          <View
            x={190}
            y={100}
            width={25}
            height={25}
            src={
              isFavorited()
                ? "https://i.ibb.co/pRyqmgX/icons8-love-100-2.png"
                : "https://i.ibb.co/kHcHCDb/icons8-love-100.png"
            }
          />
        </View>
      </View>
    );
  }

  onMount(() => {
    debouncedHandleCategoryPress = useDebounce((categoryID: any) => {
      handleCategoryPress(categoryID);
      setTimeout(() => {
        setIsChannelsLoading(false);
        setTimeout(() => {
          setIsReadyToRight(false);
        }, 200);
      }, 50);
    }, 1000);
  });

  createEffect(() => {
    const index = focusStore.focusedIndexChannels;
    let timeout: any;

    if (index !== null) {
      timeout = setTimeout(() => {
        timeoutId = true;
      }, 1200);
    } else if (index === 0) {
      timeoutId = true;
    }

    // Cleanup to clear timeout when the effect re-runs or component unmounts
    onCleanup(() => {
      clearTimeout(timeout);
      // timeoutId = false;
    });
  }, []);

  createEffect(() => {
    if (isScreenFirstOpen()) {
      handleCategoryPress("99999");
      setIsScreenFirstOpen(false);
    }

    localStorage.setItem("@emptyCategory", providerCategory().pages().length > 0 ? "false" : "true");
  }, [isScreenFirstOpen()]);

  createEffect(() => {
    const existingChannels = localStorage.getItem("@favoriteChannels");
    let existingChannelsArray: any = existingChannels ? JSON.parse(existingChannels) : [];

    setListOfFav(existingChannelsArray);
  }, [listOfFav()]);

  createEffect(() => {
    const enterID: any = focusStore.enterChannels;
    console.log("te shikohet kjo", focusStore.enterChannels);

    if (enterID !== null) {
      handleCategoryPress(enterID);
      setLastCategoryID(enterID);
    } else {
      setSomething(false);
    }
  });

  // Trigger the effect when the component enters the screen
  createEffect(() => {
    setCanExecuteEscape(false); // Initial state when entering
    const timer = setTimeout(() => {
      setCanExecuteEscape(true); // Set to true after 3 seconds
    }, 3000);

    // Cleanup the timer if the component is unmounted
    onCleanup(() => clearTimeout(timer));
  });

  onMount(() => {
    // Initialize the value only if it's not set
    if (focusStore.focusedIndexChannels === null || focusStore.focusedIndexChannels === undefined) {
      //@ts-ignore
      setFocusStore("focusedIndexChannels", 0);
    }

    // Focus logic with timeout
    searchIndexTimeout = setTimeout(() => {
      viewRef?.setFocus();
      setSomething(false);
    }, 1200);
  });

  // Cleanup to clear timeout when leaving the screen
  onCleanup(() => {
    clearTimeout(searchIndexTimeout);
  });

  return (
    <View
      color={props.isFromLiveTv ? hexColor("#80808080") : hexColor("#000000")}
      onEscape={() => {
        if (canExecuteEscape()) {
          navigate("/home");
          videoPlayerPreview.destroy();
          setFocusStore("focusedIndexChannels", null);
          setFocusStore("thumbnailIndexChannels", null);
          setFocusStore("enterChannels", null);
          setFocusStore("colonIndexChannels", null);

          setPreviewToNotShow(false);

          clearTimeout(searchIndexTimeout);
          setLastStream(null);
          onCleanup(() => {
            if (searchIndexTimeout) {
              clearTimeout(searchIndexTimeout);
              searchIndexTimeout = null;
            }
          });
        }
      }}
    >
      <Show when={!shouldStart()}>
        <View color={hexColor("#000")} zIndex={999} width={580} height={320} x={660} y={60}>
          <View x={200} y={80}>
            <LoadingIndicator isCategoriesIndicator={true} />
          </View>
        </View>
      </Show>
      <View y={30}>
        <Show
          when={providerCategory().pages() && providerCategory().pages().length > 0}
          fallback={
            <View x={750} y={500}>
              <Text fontSize={30} color={hexColor("#7d7e82")}>
                {t("noChannels")}
              </Text>
            </View>
          }
        >
          <View
            x={150}
            y={10}
            onEnter={() => {
              navigate("/searchlive");
              // localStorage.removeItem("focusedIndex");
              setFocusStore("focusedIndexChannels", null);
              videoPlayerPreview.destroy();
            }}
            // @ts-ignore
            style={{
              borderRadius: 30,
              width: 400,
              height: 70,
              scale: 1,
              zIndex: 2,
              color: hexColor("#222222"),
              transition: { scale: { duration: 250, easing: "ease-in-out" } },
              border: { width: 0, color: 0x00000000 },
              focus: { scale: 1.1, color: hexColor("#128DD3") },
            }}
            ref={search}
            onDown={() => myButton.setFocus()}
          >
            <View x={25} y={20} width={30} height={30} src="https://i.ibb.co/rHLz71b/search-50.png" />

            <Text x={80} y={25} width={60} style={{ color: hexColor("#7d7e82"), fontSize: 22, width: 10 }}>
              {t("searchChannels")}
            </Text>
          </View>

          <View y={80} clipping>
            {/* @ts-ignore */}
            <LazyUp
              ref={myButton}
              x={150}
              onSelectedChanged={setLastElement}
              y={10}
              gap={15}
              onRight={() => {
                if (isReadyToRight()) {
                  console.log("nuke eshete gatiakome");
                } else {
                  viewRef.setFocus();
                }
              }}
              component={Column}
              scroll="always"
              onUp={() => {
                search.setFocus();
              }}
              // direction="column"
              upCount={12}
              selected={focusStore.focusedIndexChannels === null ? 0 : focusStore.focusedIndexChannels}
              // selected={14}
              autofocus={true}
              each={[{ category_name: "Favorites", categoryID: "99999" }, ...providerCategory().pages()]}
            >
              {(item, index: any) => (
                <ButtonContainer
                  style={{
                    borderRadius: 20,
                    color: index === lastElement() ? hexColor("#128DD3") : hexColor("#222222"),
                    focus: { color: hexColor("#128DD3"), textColor: hexColor("#ffffff") },
                    transition: {
                      scale: { duration: 250, easing: "ease-in-out" },
                    },
                    height: 70,
                    width: 400,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onBlur={() => {
                    // setFocusStore("thumbnailIndexChannels", null);
                  }}
                  onFocus={() => {
                    if (focusStore.enterChannels !== null) {
                      return;
                    }

                    if (lastCategoryID() === item().categoryID) {
                    } else {
                      setIsChannelsLoading(true);

                      setIsReadyToRight(true);
                    }

                    const currentCategoryID = item().categoryID;
                    if (something() === true) {
                    } else {
                      debouncedHandleCategoryPress(currentCategoryID);
                      setLastCategoryID(currentCategoryID);
                    }

                    // localStorage.setItem("focusedIndex", index);
                    setFocusStore("focusedIndexChannels", index);
                  }}
                  onUp={() => {
                    if (timeoutId === false) {
                      return true;
                    } else {
                      setFocusStore("thumbnailIndexChannels", null);
                      setFocusStore("colonIndexChannels", null);
                    }
                  }}
                  onDown={() => {
                    if (timeoutId === false) {
                      return true;
                    } else {
                      setFocusStore("thumbnailIndexChannels", null);
                      setFocusStore("colonIndexChannels", null);
                    }
                  }}
                >
                  <Text fontSize={24} x={23} y={23}>
                    {"   "}
                    {item().category_name.length > 24
                      ? `${item().category_name.substring(0, 24)}...`
                      : item().category_name}
                  </Text>
                </ButtonContainer>
              )}
            </LazyUp>
          </View>

          <View
            ref={preview}
            x={650}
            y={20}
            height={350}
            width={600}
            // onLeft={() => {
            // 	myButton.setFocus();
            // }}
            clipping
            style={{
              color: hexColor("#222222"),
              borderRadius: 15,
              focus: { color: hexColor("#128DD3"), textColor: hexColor("#ffffff") },
            }}
            onDown={() => {
              viewRef.setFocus();
              setIsPreviewFocused(false);
            }}
            onLeft={() => {
              myButton.setFocus();
              setIsPreviewFocused(false);
            }}
            onRight={() => {
              addFavButtonRef.setFocus();
              setIsPreviewFocused(false);
            }}
            onEnter={() => customEnter(channelData().data.stream_id)}
            onFocus={() => setIsPreviewFocused(true)}
          >
            <Text
              x={180}
              y={140}
              style={{
                color: isPreviewFocused() ? hexColor("#ffffff") : hexColor("#929496"),
                fontSize: 20,
              }}
            >
              {t("tapToPlay")}
            </Text>

            <Show when={channelLogo()}>
              <View x={250} y={200} src={channelLogo()} width={120} height={75} />
            </Show>
          </View>

          <View
            x={1300}
            y={20}
            width={900}
            clipping
            plinko
            onEnter={() => {
              addToFavorites(channelData());
            }}
          >
            <Show when={isDataReady()}>
              <SelectedChannelEpg channel={channelData().data} />

              <View
                ref={addFavButtonRef}
                x={50}
                y={295}
                width={250}
                height={55}
                color={hexColor("#222222")}
                clipping
                plinko
                onLeft={() => {
                  preview.setFocus();
                }}
                onDown={() => {
                  viewRef.setFocus();
                }}
                style={{
                  color: hexColor("#222222"),
                  focus: { color: hexColor("#128DD3"), textColor: hexColor("#ffffff") },
                  borderRadius: 30,
                }}
              >
                <Text x={23} y={20} style={{ color: hexColor("#ffffff"), fontSize: 15 }}>
                  {isFavChannel() ? "Remove from Favorites" : "Add to Favorites"}
                </Text>

                <View
                  x={200}
                  y={13}
                  width={25}
                  height={25}
                  src={
                    isFavChannel()
                      ? "https://i.ibb.co/pRyqmgX/icons8-love-100-2.png"
                      : "https://i.ibb.co/kHcHCDb/icons8-love-100.png"
                  }
                />
              </View>
            </Show>
          </View>

          <View
            x={480}
            y={390}
            onLeft={() => {
              myButton.setFocus();
            }}
            onUp={() => {
              preview.setFocus();
            }}
            clipping
            style={styles.itemsContainerChannelMenu}
          >
            <Show
              when={provider().pages()}
              fallback={
                <View x={500} y={100}>
                  <LoadingIndicator isCategoriesIndicator={false} />
                </View>
              }
            >
              {/* Check if there are no pages (empty category) */}
              <Show
                when={provider().pages().length > 0}
                fallback={
                  <View x={500} y={250}>
                    {/* {showMessage() && ( */}
                    <Text y={50} style={{ color: hexColor("#7d7e82"), fontSize: 22, width: 10 }}>
                      {t("categoryEmpty")}
                    </Text>
                    {/* )} */}
                  </View>
                }
              >
                <Show
                  when={isChannelsLoading()}
                  fallback={
                    //@ts-ignore
                    <LazyUp
                      ref={viewRef}
                      style={styles.Column}
                      plinko
                      onBlur={() => {
                        // localStorage.removeItem("enter");
                        // setFocusStore("enterChannels", null);
                      }}
                      component={Column}
                      scroll="always"
                      // direction="column"
                      upCount={3}
                      each={provider().pages()}
                      selected={
                        // localStorage.getItem("colonIndex") === null
                        focusStore.colonIndexChannels === null
                          ? 0
                          : (() => {
                              const index = focusStore.colonIndexChannels;
                              if (index !== null) {
                                setTimeout(() => {}, 300);

                                return index;
                              }
                            })()
                      }
                    >
                      {(items, i: any) => (
                        <TileRowChannel
                          items={items()}
                          onEnter={() => {
                            // localStorage.setItem("colonIndex", i);
                            setFocusStore("colonIndexChannels", i);
                          }}
                          width={
                            provider().pages()[i].length === 2
                              ? 560
                              : provider().pages()[i].length === 3
                                ? 850
                                : 1140
                          }
                        />
                      )}
                    </LazyUp>
                  }
                >
                  <View x={600} y={200}>
                    <LoadingIndicator isCategoriesIndicator={true} />
                  </View>
                </Show>
              </Show>
            </Show>
          </View>
        </Show>
      </View>
    </View>
  );
};

export default ChannelMenu;
