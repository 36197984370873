// src/stores/languageStore.ts
import { createSignal, createMemo, onMount } from "solid-js";
import * as i18n from "@solid-primitives/i18n";
import en from "../translations/en";
import it from "../translations/it";
import de from "../translations/de";
import es from "../translations/es";
import gr from "../translations/gr";
import fr from "../translations/fr";

const dictionaries = {
  en: en,
  it: it,
  es: es,
  de: de,
  gr: gr,
  fr: fr,
};

// Create a signal for the locale (default is "en")
const [locale, setLocale] = createSignal("en");

// Load the saved locale from localStorage on app start
onMount(() => {
  const savedLocale = localStorage.getItem("app_locale");
  if (savedLocale) {
    setLocale(savedLocale);
  }
});

// Function to change the locale and store it in localStorage
const changeLanguage = (lang: string) => {
  setLocale(lang);
  localStorage.setItem("app_locale", lang);
};

// Memoized dictionary based on locale
const dict = createMemo(() => i18n.flatten(dictionaries[locale()]));
const t = i18n.translator(dict);

// Export the signals and functions
export { locale, setLocale, changeLanguage, t };
